<template>
    <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative max-sm:my-8">

        <personelInformationModal ref="personelInformationModal" :Detail="selected" />
        <filterPanel ref="filterPanel" @filterApplied="handleFilterApplied" @filterReset="handleFilterReset" 
        :search="search"
        >

            <template #body>
                <customInput v-model="search" @keypress.enter="getSearch()" type="text"
                :label="$t('hired.index.table.searchEmployeeTitle')"
                            :placeholder="$t('hired.index.table.searchEmployee')" />
            </template>
        </filterPanel>
        <customTable :items="list" :columns="tableColumns" :loading="load" :current-page="currentPage"
            :page-count="pageCount" :total-count="totalCount" :size="'sm'" @page-change="handlePageChange"
            @row-click="handleRowClick"
            :height="'calc(100vh - 300px)'"
            >
            <template #header>
                <div class="flex items-center justify-end w-full gap-4">


                    <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
                        <input v-model="search" @keypress.enter="getSearch()" type="text"
                            class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                            :placeholder="$t('hired.index.table.searchEmployee')" />
                        <button class="px-3 textcol absolute right-0">
                            <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
                        </button>
                        <button @click="$refs.filterPanel.toggleSidebar()" class="absolute right-9">
                            <div class="relative group flex justify-center">
                                <Icon :icon="$refs?.filterPanel?.hasActiveFilters ? 'mage:filter-fill' : 'mage:filter'
                                    " class="text-cvus-primary text-2xl" :class="{
                                    'text-cvus-primary-light': $refs?.filterPanel?.hasActiveFilters,
                                    'text-cvus-primary': !$refs?.filterPanel?.hasActiveFilters,
                                }" />
                            </div>
                        </button>
                    </div>
                </div>
            </template>

            <template #id="{ item }">
                <div class="font-bold">
                    #{{ item.id }}
                </div>
            </template>
            <template #fullname="{ item }">
                <div class="flex items-center gap-2">
                    {{ formatText(item.fullname) }}
                    <div v-if="isWithinTwoDays(item.addDate)"
                        class="bg-cvus-primary-light text-white px-1 rounded-md text-xs">
                        {{ $t("new") }}
                    </div>
                </div>
            </template>
            <template #position="{ item }">
                <div class="flex flex-col text-xs">
                    <span class="">
                        <span class="text-[10px] text-gray-500 font-bold">
                            #{{ item.projectId }} </span>
                        {{ formatText(item.projectName) }}
                    </span>
                    <span class="text-[10px] text-gray-500">
                        {{ formatText(item.position) }}
                    </span>
                </div>
            </template>
            <template #projectAdvisors="{ item }">
                <div v-if="isContentOverflow(formatText(listFormatController(item.projectAdvisors)?.join(', ') || ''), 20)"
                    class="relative group">
                    <div class="truncate text-ellipsis">
                        {{ formatText(listFormatController(item.projectAdvisors) || '-')?.join(', ').slice(0, 20) + '...'
                        }}
                    </div>

                    <tooltip :title="listFormatController(item.projectAdvisors)?.join(', ') || '-'" :top="true" />
                </div>

                <div v-else>
                    {{ formatText(listFormatController(item.projectAdvisors)?.join(', ') || '-') }}
                </div>
            </template>

            <template #actions="{ item }">
                <div class="flex gap-4 w-[80px] justify-start items-center !h-8 max-h-8">
                    <asyncButton type="button" @click="showpersonelInformation(item)" :hiddenText="true"
                        v-if="item.shortListStatus" :buttonClass="'w-full !px-0 !gap-0'"
                        :awesome="'fa-solid fa-magnifying-glass text-xs font-semibold'"
                        containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent !shadow-none"
                        imgClass="w-5" container-class="w-full md:w-auto" :isTooltip="true"
                        :toolTipTitle="$t('project.tableInterview.tooltip.profile')" :tooltipTop="true"
                        spinnerClass="!border-t-transparent !border-black !text-black" tooltipRotateClass="ml-4" />
                    <asyncButton type="button" @click="deleteItem(item)" :hiddenText="true"
                        v-if="item.shortListStatus != 8 && canRender(7, 18)" :buttonClass="'w-full !px-0 !gap-0'"
                        :awesome="'fa-solid fa-xmark text-base text-red-600'"
                        containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent !shadow-none"
                        imgClass="w-5" container-class="w-full md:w-auto" :isTooltip="true"
                        :toolTipTitle="$t('project.tableInterview.tooltip.reject')" :tooltipTop="true"
                        spinnerClass="!border-t-transparent !border-black !text-black" tooltipRotateClass="ml-4" />
                </div>
            </template>
        </customTable>

    </div>
</template>

<script>
import personelInformationModal from "../project/projectUser/components/shortlist/components/personelInformation/index.vue";
import customTable from "@/components/customTable.vue";
import customInput from "@/components/customInput.vue";
import filterPanel from "./components/filterPanel.vue";
import moment from "moment";
import "moment/locale/tr";
import { hired } from "@/networking/urlmanager";
import asyncButton from "@/components/button.vue";
import tooltip from "@/components/tooltip.vue";
export default {
    name: "hired-page",
    components: {
        asyncButton,
        personelInformationModal,
        customTable,
        tooltip,
        filterPanel,
        customInput
    },
    data() {
        return {
            list: [],
            checkedList: [],
            search: this.$route.query.search || "",
            currentPage: this.$route.query.page || 0,
            totalCount: "",
            pageCount: 1,
            rowId: "",
            load: false,
            selected: "",
            activeFilters: this.initializeFiltersFromQuery(),
        };
    },
    computed: {
        tableColumns() {
            return [
                {
                    key: 'id',
                    label: this.$t("hired.index.table.id"),
                    width: '100px',
                },
                {
                    key: 'fullname',
                    label: this.$t("hired.index.table.name"),
                    width: '200px',
                    clickable: true,
                    format: (value) => this.formatText(value)
                },
                {
                    key: 'position',
                    width: '200px',
                    label: this.$t("hired.index.table.position"),
                    format: (value) => value ? this.formatText(value) : this.$t("hired.index.unspecified"),
                    clickable: true,

                },
                {
                    key: 'projectAdvisors',
                    label: this.$t("hired.index.table.advisors"),
                    width: '160px',
                },
                {
                    key: 'hiredDate',
                    label: this.$t("hired.index.table.hiredDate"),
                    format: 'date',
                    width: '160px',
                },
                {
                    key: 'dismissedDate',
                    label: this.$t("hired.index.table.firedDate"),
                    format: 'date',
                    width: '160px',
                },
            ]
        }
    },
    methods: {
        initializeFiltersFromQuery() {
            const query = this.$route.query;
            const filters = [];

            // Add position filter if exists in query
            if (query.positions) {
                try {
                    const positions = JSON.parse(query.positions);
                    if (positions) {
                        filters.push({
                            name: "jobTitle",
                            value: positions
                        });
                    }
                } catch (e) {
                    console.error("Error parsing positions from query:", e);
                }
            }

            // Add date range filter if exists in query
            if (query.startDate || query.endDate) {
                filters.push({
                    name: "hiredDate",
                    min: query.startDate ? moment(query.startDate).format('YYYY-MM-DD') : "",
                    max: query.endDate ? moment(query.endDate).format('YYYY-MM-DD') : ""
                });
            }

            return filters.length > 0 ? filters : null;
        },
        getAll() {
            this.load = true;
            this.list = [];
            this.axios
                .post(hired.getAll, {

                    filterList: this.activeFilters ? this.activeFilters : undefined
                }, {
                    params: {
                        page: this.currentPage,
                        search: this.search,
                    },
                })
                .then((response) => {
                    this.list = response.data.data.detail;
                    this.totalCount = response.data.data.totalCount;
                    this.pageCount = response.data.data.pageCount;
                    this.load = false;
                })
                .catch((error) => {
                    this.list = [];
                    this.totalCount = 0;
                    this.pageCount = 1;
                    this.load = false;
                });
        },

        selectAll(val) {
            if (val.target.checked) {
                for (let index = 0; index < this.list.length; index++) {
                    this.checkedList.push(index);
                }
            } else {
                this.checkedList = [];
            }
        },

        getSearch() {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: 0,
                    search: this.search,
                },
            });
        },
        isWithinTwoDays(dateString) {
            const now = moment();
            const date = moment(dateString);
            const diffDays = now.diff(date, "days");
            return diffDays <= 2;
        },

        deleteItem(item) {
            this.$swal({
                text: this.$t("project.tableInterview.verifiedMessage.cancelReason"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: this.$t(
                    "project.tableInterview.verifiedMessage.cancelReasonConfirm"
                ),
                cancelButtonText: this.$t(
                    "project.tableInterview.verifiedMessage.cancelReasonDecline"
                ),
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteFire(item, result)
                }
            });
        },
        async deleteFire(item, result) {

            this.load = true;

            await this.axios
                .delete(hired.setDismissed, {
                    data: {
                        rowId: item.shortListRowId,
                    projectId: item.projectId,
                    cvId: item.cvId,
                    inadequateNote: result.value ? result.value : undefined,
                    fullname: item.fullname,
                    }
                })
                .then((res) => {
                    this.successMessage(res.data.message);
                    this.list = [];
                    this.getAll();
                })
                .catch((err) => {
                    const errorMessage = err.response
                        ? err.response.data.message
                        : err.message;
                    this.authController(errorMessage);
                }).finally(() => {
                    this.load = false;
                });
        },
        getDate(val) {
            if (val) return moment(val).format("LL");
            else return '-';
        },
        showpersonelInformation(val) {
            this.selected = val;
            this.$refs.personelInformationModal.show();
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page
                }
            });
        },
        handleRowClick({ item, key }) {
            if (key === 'fullname') {
                this.$router.push(`/cv/profile/${item.id}`);
            } else if (key === 'position') {
                this.goToPosition(item);
            }

        },
        goToPosition(item) {
            this.$router.push({
                path: `/project/user`,
                query: {
                    id: item.projectId,
                    projectName: item.projectName,
                },
            });
        },
        handleFilterApplied(filterData) {
            this.activeFilters = filterData.filterList;
            this.currentPage = 0;

            // Update URL with filter parameters
            const query = {
                page: 0,
                search: this.search || undefined
            };

            // Add filters to query
            filterData.filterList.forEach(filter => {
                if (filter.name === "jobTitle") {
                    query.positions = JSON.stringify(filter.value);
                } else if (filter.name === "hiredDate") {
                    if (filter.min) query.startDate = moment(filter.min).format('YYYY-MM-DD');
                    if (filter.max) query.endDate = moment(filter.max).format('YYYY-MM-DD');
                }
            });

            this.$router.replace({ query });
        },
        handleFilterReset() {
            this.activeFilters = null;
            this.currentPage = 0;
            this.search = "";
            // Remove filter parameters from URL
            const query = {
                page: 0,
                search: this.search || undefined
            };

            this.$router.replace({ query });
        },
    },

    // mounted() {
    //   this.getAll();
    // },
    created() {
        this.getAll();
        
        // Wrap methods with permission checks
        this.deleteItem = this.withModuleActionCheck(this.deleteItem, 7, 18);
    },

    watch: {
        currentPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: val,
                },
            });

        },
        search(val) {
            if (!val) {
                const query = { ...this.$route.query };
                delete query.search;
                query.page = 0;
                this.$router.push({ query });
            }
        },

        $route(to, from) {
            this.currentPage = to.query.page || 0;
            this.activeFilters = this.initializeFiltersFromQuery();
            this.getAll();
        },
    },
};
</script>