var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col border rounded-lg overflow-hidden shadow-sm h-auto min-h-fit",class:[
       _vm.colorVariants[_vm.variant].card,
       _vm.borderVariants[_vm.variant]
     ]},[_c('div',{class:[
      'border-b z-10',
      _vm.colorVariants[_vm.variant].header,
      _vm.borderVariants[_vm.variant],
      _vm.size === 'sm' ? 'p-2' : _vm.size === 'lg' ? 'p-6' : 'p-4',
      { 'sticky top-0': _vm.stickyHeader },
      { 'cursor-pointer': _vm.isAccordion }
    ],on:{"click":function($event){_vm.isAccordion ? _vm.toggleBody() : null}}},[_c('div',{staticClass:"flex justify-between items-center"},[_vm._t("title",function(){return [_c('h3',{class:[
          'm-0 font-semibold',
          _vm.size === 'sm' ? 'text-base' : _vm.size === 'lg' ? 'text-2xl' : 'text-xl',
          _vm.colorVariants[_vm.variant].text
        ]},[_vm._v(_vm._s(_vm.title))])]}),(_vm.isAccordion)?_c('div',{staticClass:"ml-2"},[_c('Icon',{class:['h-5 w-5 transition-transform', { 'transform rotate-180': !_vm.isBodyVisible }, _vm.colorVariants[_vm.variant].text],attrs:{"icon":'mdi:chevron-down'}})],1):_vm._e()],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBodyVisible),expression:"isBodyVisible"}],class:[
      'flex flex-col transition-all duration-300 overflow-y-auto',
      _vm.colorVariants[_vm.variant].body,
      _vm.size === 'sm' ? 'p-2' : _vm.size === 'lg' ? 'p-6' : 'p-4'
    ]},[_vm._t("default"),(_vm.loading)?_c('customLoading'):_vm._e()],2),(_vm.$slots.footer && _vm.isBodyVisible)?_c('div',{class:[
      'border-t',
      _vm.colorVariants[_vm.variant].footer,
      _vm.borderVariants[_vm.variant],
      _vm.size === 'sm' ? 'p-2' : _vm.size === 'lg' ? 'p-6' : 'p-4'
    ]},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }