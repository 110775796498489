import store from '../store';

/**
 * A mixin that provides methods for authorization checks
 */
export default {
  methods: {
    /**
     * Wrap a method with authorization check
     * @param {Function} method - The method to wrap
     * @param {Function} authCheck - Function that returns true if user is authorized
     * @param {Object} options - Additional options
     * @returns {Function} A new function that checks auth before executing the method
     */
    withAuth(method, authCheck, options = {}) {
      return async (...args) => {
        // Check if user is authenticated
        const isAuth = store.state.userData.isAuth;
        if (!isAuth) {
          if (options.onUnauthorized) {
            options.onUnauthorized.call(this);
          } else if (!options.silent) {
            this.$router.push('/');
          }
          return Promise.reject(new Error('Unauthorized'));
        }
        
        // If authCheck function is provided, run that check
        if (typeof authCheck === 'function') {
          const isAuthorized = authCheck.call(this);
          if (!isAuthorized) {
            if (options.onUnauthorized) {
              options.onUnauthorized.call(this);
            } else if (!options.silent) {
              // Show warning message
              if (this.warningMesage) {
                this.warningMesage('You do not have permission to perform this action');
              }
            }
            return Promise.reject(new Error('Permission denied'));
          }
        }
        
        // If all checks pass, execute the original method
        return method.apply(this, args);
      };
    },
    
    /**
     * Check if current user has a specific authority
     * @param {Number|Array} authority - Authority level(s) required
     * @returns {Boolean} True if user has required authority
     */
    hasAuthority(authority) {
      const userAuthority = store.state.userData.authority;
      
      if (Array.isArray(authority)) {
        return authority.includes(userAuthority);
      }
      
      return userAuthority === authority;
    },
    
    /**
     * Check if current user has access to a specific resource
     * @param {String} resourceType - Type of resource
     * @param {*} resourceId - ID of the resource
     * @returns {Boolean} True if user has access to the resource
     */
    hasResourceAccess(resourceType, resourceId) {
      // Implement your resource-specific access logic here
      // For example, check if the user owns the resource or
      // has the right role for accessing it
      
      switch (resourceType) {
        case 'project':
          // Check project access
          return true; // Replace with actual logic
          
        case 'company':
          // Check company access
          return store.state.activeCompany?.id === resourceId;
          
        default:
          return false;
      }
    },
    
    /**
     * Check if user has permission for a specific module action
     * @param {Number} moduleId - Module ID
     * @param {Number} actionId - Action ID
     * @returns {Boolean} True if user has permission
     */
    hasModuleActionPermission(moduleId, actionId) {
      // Use the hasPermission getter from the store
      return store.getters.hasPermission(moduleId, actionId);
    },
    
    /**
     * Wrap a method with module action permission check
     * @param {Function} method - The method to wrap
     * @param {Number} moduleId - Module ID
     * @param {Number} actionId - Action ID
     * @param {Object} options - Additional options
     * @param {Function} options.errorCallback - Custom error handler to show error message
     * @returns {Function} A wrapped function that checks permissions first
     */
    withModuleActionCheck(method, moduleId, actionId, options = {}) {
      return this.withAuth(method, 
        () => this.hasModuleActionPermission(moduleId, actionId),
        {
          ...options,
          onUnauthorized: function() {
            // If a custom error callback is provided, call it
            if (options.errorCallback && typeof options.errorCallback === 'function') {
              options.errorCallback.call(this, moduleId, actionId);
            } else if (options.onUnauthorized) {
              options.onUnauthorized.call(this);
            } else if (!options.silent) {
              // Use the notification helper
              this.warningMesage(this.$t("general.permissionDenied"));
            }
          }
        }
      );
    }
  }
}; 