<template>
    <div class="w-full pt-12">
        <navbar
            :title="$t('settings.position.title')"
            :description="$t('settings.position.description')"
        />

        <div class="mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4">
            <customTabs 
                :tabs="tabs" 
                :activeTab="activeTab" 
                @tab-click="handleTabClick"
            />

            <div class="py-4">
                <keep-alive>
                    <component :is="activeComponent"></component>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import Inadequate from './inadequate/index.vue'
import PersonalInformation from './personalInformation/index.vue'
import Reference from './reference/index.vue'
import navbar from '../components/navbar.vue'
import customTabs from '@/components/customTabs.vue'

export default {
    name: 'PositionSettings',
    components: {
        Inadequate,
        PersonalInformation,
        Reference,
        navbar,
        customTabs
    },
    data() {
        return {
        }
    },
    computed: {
        tabs() {
            // return [
            //     { id: 'personal-information', name: this.$t('settings.position.tabs.personalInformation')  },
            //     { id: 'reference', name: this.$t('settings.position.tabs.reference') },
            //     { id: 'inadequate', name: this.$t('settings.position.tabs.inadequate') }
            // ];
            const tabs = [
               
            ]
            if(this.canRender(6, 26)){
                tabs.push({ id: 'personal-information', name: this.$t('settings.position.tabs.personalInformation')  })
            }
            if(this.canRender(6, 27)){
                tabs.push({ id: 'reference', name: this.$t('settings.position.tabs.reference') })
            }
            if(this.canRender(6, 28)){
                tabs.push({ id: 'inadequate', name: this.$t('settings.position.tabs.inadequate') })
            }
            return tabs
        },
        activeTab() {
            // Get tab from query parameter or use default
            const tabFromQuery = this.$route.query.tab
            if (tabFromQuery && this.isValidTab(tabFromQuery)) {
                return tabFromQuery
            }
            // return 'personal-information'
            return this.tabs[0].id
        },
        activeComponent() {
            switch (this.activeTab) {
                case 'personal-information':
                    return 'PersonalInformation'
                case 'reference':
                    return 'Reference'
                case 'inadequate':
                    return 'Inadequate'
                default:
                    return 'PersonalInformation'
            }
        }
    },
    methods: {
        isValidTab(tabId) {
            return this.tabs.some(tab => tab.id === tabId)
        },
        handleTabClick(tabId) {
            // Update the URL query parameter
            this.$router.replace({
                query: { ...this.$route.query, tab: tabId }
            }).catch(err => {
                // Ignore redundant navigation errors
                if (err.name !== 'NavigationDuplicated') {
                    throw err
                }
            })
        }
    }
}
</script>