var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdownContainer",staticClass:"relative"},[_c('div',{ref:"toggleButton",staticClass:"cursor-pointer flex items-center justify-center",on:{"click":_vm.toggleDropdown}},[_vm._t("toggle",function(){return [_c('button',{staticClass:"flex items-center gap-1"},[_vm._t("toggle-icon",function(){return [_c('i',{staticClass:"fas fa-chevron-down transition-transform",class:{ 'rotate-180': _vm.isOpen }})]}),_vm._t("toggle-text",function(){return [_vm._v("Toggle")]})],2)]})],2),(_vm.isOpen && _vm.closeOnClickOutside)?_c('Teleport',{attrs:{"to":"body"}},[_c('div',{staticClass:"fixed inset-0 z-[9998]",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeDropdown.apply(null, arguments)}}})]):_vm._e(),_c('Teleport',{attrs:{"to":"body"}},[_c('transition',{attrs:{"name":"dropdown"}},[(_vm.isOpen)?_c('div',{ref:"dropdownElement",staticClass:"fixed z-[9999] dropdown-content",style:(_vm.dropdownStyle)},[_c('div',{staticClass:"dropdown-arrow absolute w-3 h-3 bg-white transform rotate-45 border",class:[
            !_vm.isOpeningUpwards ? 'bottom-arrow border-b-0 border-r-0 bottom-0 ' : 'top-arrow border-t-0 border-l-0 top-0'
          ],style:([_vm.arrowStyle, { backgroundColor: _vm.arrowColor }])}),_c('div',{staticClass:"bg-white border border-gray-200 rounded shadow-lg overflow-y-auto w-full h-full",class:[
            { 'max-h-[150px]': _vm.size === 'sm' },
            { 'max-h-[200px]': _vm.size === 'md' },
            { 'max-h-[250px]': _vm.size === 'lg' }
          ]},[_vm._t("default")],2)]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }