<template>
  <div class="flex flex-col border rounded-lg overflow-hidden shadow-sm h-auto min-h-fit"
       :class="[
         colorVariants[variant].card,
         borderVariants[variant]
       ]">
    <!-- Title section with sticky option -->
    <div 
      :class="[
        'border-b z-10',
        colorVariants[variant].header,
        borderVariants[variant],
        size === 'sm' ? 'p-2' : size === 'lg' ? 'p-6' : 'p-4',
        { 'sticky top-0': stickyHeader },
        { 'cursor-pointer': isAccordion }
      ]"
      @click="isAccordion ? toggleBody() : null"
    >
      <!-- Use title slot if provided, otherwise use title prop -->
      <div class="flex justify-between items-center">
        <slot name="title">
          <h3 :class="[
            'm-0 font-semibold',
            size === 'sm' ? 'text-base' : size === 'lg' ? 'text-2xl' : 'text-xl',
            colorVariants[variant].text
          ]">{{ title }}</h3>
        </slot>
        
        <!-- Accordion toggle icon -->
        <div v-if="isAccordion" class="ml-2">
          <Icon 
            :icon="'mdi:chevron-down'"
            :class="['h-5 w-5 transition-transform', { 'transform rotate-180': !isBodyVisible }, colorVariants[variant].text]"
          />
        </div>
      </div>
    </div>
    
    <!-- Scrollable body content -->
    <div 
      v-show="isBodyVisible"
      :class="[
        'flex flex-col transition-all duration-300 overflow-y-auto',
        colorVariants[variant].body,
        size === 'sm' ? 'p-2' : size === 'lg' ? 'p-6' : 'p-4'
      ]" 
      
    >
      <slot></slot>
      <customLoading v-if="loading" />
    </div>
    
    <!-- Footer section - only render if footer slot has content -->
    <div 
      v-if="$slots.footer && isBodyVisible" 
      :class="[
        'border-t',
        colorVariants[variant].footer,
        borderVariants[variant],
        size === 'sm' ? 'p-2' : size === 'lg' ? 'p-6' : 'p-4'
      ]"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import customLoading from "@/components/customLoading.vue";
export default {
  name: 'CardComponent',
  components: {
    customLoading
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
   
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md', 'lg'].includes(value)
    },
    isAccordion: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'primary', 'success', 'warning', 'danger', 'info', 'dark'].includes(value)
    },
    loading: {
      type: Boolean,
      default: false
    }
  
  },
 
  data() {
    return {
      isBodyVisible: this.defaultOpen,
      colorVariants: {
        default: {
          card: 'bg-white',
          header: 'bg-gray-50',
          body: 'bg-white',
          footer: 'bg-gray-50',
          text: 'text-gray-800'
        },
        primary: {
          card: 'bg-white',
          header: 'bg-blue-50',
          body: 'bg-white',
          footer: 'bg-blue-50',
          text: 'text-blue-700'
        },
        success: {
          card: 'bg-white',
          header: 'bg-green-50',
          body: 'bg-white',
          footer: 'bg-green-50',
          text: 'text-green-700'
        },
        warning: {
          card: 'bg-white',
          header: 'bg-yellow-50',
          body: 'bg-white',
          footer: 'bg-yellow-50',
          text: 'text-yellow-700'
        },
        danger: {
          card: 'bg-white',
          header: 'bg-red-50',
          body: 'bg-white',
          footer: 'bg-red-50',
          text: 'text-red-700'
        },
        info: {
          card: 'bg-white',
          header: 'bg-cyan-50',
          body: 'bg-white',
          footer: 'bg-cyan-50',
          text: 'text-cyan-700'
        },
        dark: {
          card: 'bg-gray-800',
          header: 'bg-gray-900',
          body: 'bg-gray-800',
          footer: 'bg-gray-900',
          text: 'text-white'
        }
      },
      borderVariants: {
        default: 'border-gray-200',
        primary: 'border-blue-200',
        success: 'border-green-200',
        warning: 'border-yellow-200',
        danger: 'border-red-200',
        info: 'border-cyan-200',
        dark: 'border-gray-700'
      }
    }
  },
  methods: {
    toggleBody() {
      this.isBodyVisible = !this.isBodyVisible;
    }
  }
}
</script>