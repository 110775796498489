<template>
  <modal name="editUser" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '45%'" :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">{{ $t("user.components.edit.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="edit()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <div class="flex justify-between items-center gap-x-2">
          <customInput v-model="firstName" type="text" min="3" max="40" :required="true"
            :title="$t('user.components.edit.table.name')" />
          <customInput v-model="lastName" type="text" min="3" max="40" :required="true"
            :title="$t('user.components.edit.table.surname')" />
        </div>
        <customInput v-model="email" type="email" min="3" max="40" :required="true"
          :title="$t('user.components.edit.table.email')" />

        <div v-if="hasCompanyAuth">
          <customSelect v-model="companyId" :title="$t('user.components.edit.table.company')"
            :placeholder="$t('user.components.edit.table.selectCompany')" :required="true" :loading="companiesLoading"
            :options="companyOptions" option-label="name" option-value="id" @input="handleCompanyChange"
            @load-more="loadMoreCompanies" />
        </div>

        <div>
          <customSelect v-model="roleId" :title="$t('user.components.edit.table.role')"
            :placeholder="$t('user.components.edit.table.selectRole')" :required="true" :loading="rolesLoading"
            :options="roleOptions" option-label="name" option-value="id" @input="handleRoleChange"
            :disabled="!hasRoleAuth" />
          <p v-if="!hasRoleAuth" class="text-red-500 text-sm mt-1">
            {{ $t("user.components.edit.noRoleAuth") }}
          </p>
        </div>

        <requiredField />
        <buttonItem :load="load" buttonClass="w-full" :disabled="!canSubmitForm" />
      </div>
    </form>
  </modal>
</template>

<script>
// global components
import customInput from "@/components/customInput.vue";
import customSelect from "@/components/customSelect.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import { users } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "editUser",
  props: ["detail"],
  components: {
    customInput,
    customSelect,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,

      id: "",
      firstName: "",
      lastName: "",
      email: "",
      companyId: null,
      companyName: "",
      roleId: null,
      roleName: "",

      // Companies data
      companiesLoading: false,
      companyOptions: [],
      companyPage: 0,
      companySearch: "",
      companyTotalPages: 1,

      // Roles data
      rolesLoading: false,
      roleOptions: [],
      rolePage: 0,
      roleSearch: "",
      roleTotalPages: 1,

      load: false,
    };
  },
  computed: {
    hasCompanyAuth() {
      return this.canRender(16, 52);
    },
    hasRoleAuth() {
      return this.canRender(1, 48);
    },
    canSubmitForm() {
      // If no company auth, we use the store's company ID or the existing company ID, so only role is needed
      if (!this.hasCompanyAuth) {
        return this.hasRoleAuth && this.roleId;
      }
      // Otherwise, need both company selection and role selection
      return (this.hasCompanyAuth && this.companyId) && (this.hasRoleAuth && this.roleId);
    },
    userCompanyId() {
      return this.$store.state.userData.companyId;
    }
  },

  methods: {
    async show() {
      await this.resetForm();

      // Set user data from detail prop first
      this.id = this.detail.id;
      this.firstName = this.detail.firstName;
      this.lastName = this.detail.lastName;
      this.email = this.detail.email;


      this.$modal.show("editUser");

      // Then load companies and roles only if authorized
      if (this.hasCompanyAuth) {
        await this.getCompanies().then(() => {
          this.companyId = this.detail.companyId;
          this.companyName = this.detail.companyName;
        });
      } else {
        // If user doesn't have company auth, ensure it's set to user's company
        this.companyId = this.userCompanyId;
        this.companyName = this.$store.state.userData.companyName;
      }

      if (this.hasRoleAuth) {
        await this.getRoles().then(() => {

          this.roleId = this.detail.roleId;
          this.roleName = this.detail.roleName;
        }
        )
      }
    },
    hide() {
      this.$modal.hide("editUser");
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.companyId = null;
      this.companyName = "";
      this.roleId = null;
      this.roleName = "";
    },

    // Company methods
    getCompanies() {
      if (!this.hasCompanyAuth) {
        return Promise.resolve();
      }

      this.companiesLoading = true;
      return this.axios
        .get(
          users.getCompaniesAll,
          {
            params: {
              page: this.companyPage,
              search: this.companySearch
            }
          }
        )
        .then((res) => {
          if (res.data && res.data.data) {
            const { detail, pageCount } = res.data.data;
            this.companyTotalPages = pageCount;

            // Append or replace options based on page
            if (this.companyPage === 0) {
              this.companyOptions = detail.map(company => ({
                id: company.id,
                name: company.name
              }));
            } else {
              this.companyOptions = [
                ...this.companyOptions,
                ...detail.map(company => ({
                  id: company.id,
                  name: company.name
                }))
              ];
            }
            // Don't override the selection - the value is already set
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.companiesLoading = false;
        });
    },
    handleCompanyChange(value) {
      this.companyId = value;
      // Update company name if needed
      const selectedCompany = this.companyOptions.find(c => c.id === value);
      if (selectedCompany) {
        this.companyName = selectedCompany.name;
      }
    },
    searchCompanies(query) {
      this.companySearch = query;
      this.companyPage = 0;
      this.getCompanies();
    },
    loadMoreCompanies() {
      if (this.companyPage < this.companyTotalPages - 1) {
        this.companyPage++;
        this.getCompanies();
      }
    },

    // Role methods
    getRoles() {
      if (!this.hasRoleAuth) {
        return Promise.resolve();
      }

      this.rolesLoading = true;
      const params = {};

      if (this.hasCompanyAuth && this.companyId) {
        params.company = this.companyId;
      } else if (!this.hasCompanyAuth && this.userCompanyId) {
        params.company = this.userCompanyId;
      }

      return this.axios.get(users.getRolesAll, { params })
        .then((res) => {
          if (res.data && res.data.data) {
            this.roleOptions = res.data.data.map(role => ({
              id: role.roleId,
              name: role.name
            }));
          }
          // Don't override the selection - the value is already set
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.rolesLoading = false;
          this.companiesLoading = false;
        });
    },
    handleRoleChange(value) {
      this.roleId = value;
      // Update role name if needed
      const selectedRole = this.roleOptions.find(r => r.id === value);
      if (selectedRole) {
        this.roleName = selectedRole.name;
      }
    },
    searchRoles(query) {
      this.roleSearch = query;
      this.getRoles();
    },

    validate() {
      if (!this.firstName || this.firstName.length < 3) {
        this.warningMesage(this.$t("user.components.edit.table.nameError"));
        return false;
      }
      if (!this.lastName || this.lastName.length < 3) {
        this.warningMesage(this.$t("user.components.edit.table.surnameError"));
        return false;
      }
      if (!this.email || !/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.warningMesage(this.$t("user.components.edit.table.emailError"));
        return false;
      }

      // Only validate company if user has company auth
      if (this.hasCompanyAuth && !this.companyId) {
        this.warningMesage(this.$t("user.components.edit.table.companyError"));
        return false;
      }

      // If user doesn't have company auth, make sure we have a company ID either from store or existing data
      if (!this.hasCompanyAuth && !this.companyId && !this.userCompanyId) {
        this.warningMesage(this.$t("user.components.edit.companyRequired"));
        return false;
      }

      if (!this.roleId) {
        this.warningMesage(this.$t("user.components.edit.table.roleError"));
        return false;
      }
      return true;
    },

    edit() {
      if (!this.validate()) {
        return;
      }

      this.load = true;
      // Use selected company or user's company based on permissions
      const companyIdToUse = this.hasCompanyAuth ? this.companyId : (this.userCompanyId || this.companyId);

      this.axios
        .post(
          users.edit,
          {
            rowId: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            company: companyIdToUse,
            roleId: this.roleId
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.successMessage(res.data.message);
          this.$emit("refresh", true);
          this.hide();
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    }
  },
  mount() {
    this.show();
  },
  watch: {
    endDate(val) {
      this.endDate = val ? moment(val).format("YYYY-MM-DD") : null;
    },
    companyId(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.hasRoleAuth) {
        this.companiesLoading = true;
        this.getRoles();
        this.roleId = null;
      }
    },
  },
};
</script>

<style></style>
