<template>
  <div>
    <sideModal
      ref="referenceSide"
      modalClass="w-8/12 max-xl:w-10/12 xl:w-[82%] max-sm:w-full"
      :isHeader="true"
      :headerTitle="
        $route.query.projectName +
        ' ' +
        $t('project.projectUser.components.referenceAndPayment.title')
      "
      :isClosable="false"
    >
      <template v-slot:content>
        <div class="overflow-y-auto scroltype h-[85vh] p-2 max-sm:p-5">
          <div class="my-5 w-full" style="height: calc(100% - 100px)">
            <div>
              <customTable
                ref="tableItem"
                :items="items"
                :columns="columns"
                :loading="loading"
                :showPagination="true"
                :pageCount="pageCount"
                :totalCount="totalCount"
                :currentPage="currentPage"
                variant="secondary"
                @page-changed="handlePageChange"
              >
                <template v-slot:actions="{ item }">
                  <div class="flex gap-4" v-if="item.stateId != 6">
                   
                    <asyncButton
                    v-if="
                        item.hasReference === true &&
                        item.hasSalary === true &&
                        item.hasInvestigation === true &&
                        item.stateId != 6 &&
                        canRender(15, 46)
                      "
                          type="button"
                          @click="handleHireCandidate(item)"
                          :hiddenText="true"
                          :buttonClass="
                            'w-full !px-0 !gap-0 '
                          "
                          :awesome="
                            'fa-solid fa-check font-bold text-green-600 '
                          "
                          containerBackground="!bg-transparent !text-black md:!w-auto justify-center !w-full"
                          imgClass="w-5"
                          container-class="w-full md:w-auto"
                          :isTooltip="true"
                          :toolTipTitle="
                            $t('project.tableInterview.tooltip.hireCandidate')
                          "
                          :tooltipTop="true"
                          toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                          spinnerClass="!border-t-transparent !border-black !text-black"
                          tooltipRotateClass="ml-4"
                        >
                        </asyncButton>

                    <asyncButton
                      v-if="item.stateId != 7 && canRender(15, 45)"
                      type="button"
                      @click="deleteItem(item)"
                      :hiddenText="true"
                      :buttonClass="'w-full !px-0 !gap-0 '"
                      :awesome="'fa-solid fa-xmark text-base text-red-600'"
                      containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
                      imgClass="w-5"
                      container-class="w-full md:w-auto"
                      :isTooltip="true"
                      :toolTipTitle="
                        $t('project.tableInterview.tooltip.reject')
                      "
                      :tooltipTop="true"
                      toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                      spinnerClass="!border-t-transparent !border-black !text-black"
                      tooltipRotateClass="ml-4"
                    />

                    <asyncButton
                      v-if="item.stateId == 7"
                      type="button"
                      @click="handleContinueWithInadequate(item)"
                      :hiddenText="true"
                      :buttonClass="'w-full !px-0 !gap-0 '"
                      :awesome="'fa-solid fa-rotate-left font-bold text-green-700  '"
                      containerBackground="!bg-transparent !text-black md:!w-auto justify-center !w-full"
                      imgClass="w-5"
                      container-class="w-full md:w-auto"
                      :isTooltip="true"
                      :toolTipTitle="
                        $t('project.tableInterview.tooltip.confirm')
                      "
                      :tooltipTop="true"
                      toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                      spinnerClass="!border-t-transparent !border-black !text-black"
                      tooltipRotateClass="ml-4"
                    />
                  </div>
                  <div class="flex gap-4" v-else>
                    <asyncButton
                        v-if="item.stateId >= 5 && canRender(13, 43)"
                        type="button"
                        @click="personelInformationMail(item)"
                        :buttonClass="'w-full !px-0 !gap-0 '"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          item.isPersonal == 0
                            ? $t(
                                'project.tableInterview.tooltip.formSubmission'
                              )
                            : $t('project.tableInterview.tooltip.formSubmitted')
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
                        :spinnerClass="
                          '!border-t-transparent !border-black !text-black' +
                          (item.stateId == 5 && 'text-black text-opacity-30')
                        "
                        :loading="infoFormLoad"
                        tooltipRotateClass="ml-[85px]"
                      >
                      <Icon icon="ri:info-card-line" />
                    </asyncButton>
                    <!-- Add hire button if all three conditions are true -->
                    <asyncButton
                        v-if="item.stateId >= 5 && canRender(13, 43)"
                        type="button"
                        @click="showPersonelInformation(item)"
                        :hiddenText="true"
                        buttonClass="w-full !bg-transparent !px-0 !gap-0"
                        awesome="fa-solid fa-magnifying-glass text-xs font-semibold"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        imgClass="w-5"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          $t(
                            'project.tableInterview.tooltip.viewPersonalInformation'
                          )
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
                        :spinnerClass="
                          '!border-t-transparent !border-black !text-black' +
                          (item.stateId == 5 && 'text-black text-opacity-30')
                        "
                        tooltipRotateClass="ml-[85px]"
                      />

                  </div>
                </template>

                <!-- Custom template for candidate column -->
                <template #fullname="{ item }">
                  <div class="flex flex-col py-1 gap-1 w-full">
                    <span
                      class="cursor-pointer"
                      @click="$router.push(`/cv/profile/${item.cvId}`)"
                    >
                      {{ formatText(item.fullname) }}
                    </span>
                    <span class="text-xs text-gray-500 truncate">
                      {{ parseEmail(item.email) }}
                    </span>
                  </div>
                </template>

                <!-- State column template -->
                <template #state="{ item }">
                  <div
                      class="text-xs flex items-center"
                      :class="stateName(item.stateId).class"
                    >
                      {{ stateName(item.stateId).name }}
                    </div>
                </template>

                <!-- Reference column template -->
                <template #reference="{ item }">
                  <button
                    class="flex w-full"
                    @click="openReferencePopup(item)"
                    v-if="canRender(13, 42)"
                    :class="[
                      'px-4 py-2 rounded',
                      item.hasReference === null
                        ? 'bg-gray-200'
                        : item.hasReference
                        ? 'bg-green-600 text-white'
                        : 'bg-red-600 text-white',
                    ]"
                  >
                    <div class="flex items-center justify-between w-full gap-2">
                      <Icon icon="mdi:file-document-outline" />
                      <span>{{
                        $t(
                          "project.projectUser.components.referenceAndPayment.columns.reference"
                        )
                      }}</span>
                      <Icon icon="mdi:chevron-right" />
                    </div>
                  </button>
                </template>

                <!-- Payment column template -->
                <template #payment="{ item }">
                  <button
                    class="flex w-full"
                    v-if="canRender(13, 42)"

                    :class="[
                      'px-4 py-2 rounded',
                      item.hasSalary === null
                        ? 'bg-gray-200'
                        : item.hasSalary
                        ? 'bg-green-600 text-white'
                        : 'bg-red-600 text-white',
                    ]"
                    @click="openPaymentPopup(item)"
                  >
                    <div class="flex items-center justify-between w-full gap-2">
                      <Icon icon="mdi:currency-usd" />
                      <span>{{
                        $t(
                          "project.projectUser.components.referenceAndPayment.columns.payment"
                        )
                      }}</span>
                      <Icon icon="mdi:chevron-right" />
                    </div>
                  </button>
                </template>

                <!-- Investigation column template -->
                <template #investigation="{ item }">
                  <button
                  v-if="canRender(13, 42)"

                    class="flex w-full"
                    :class="[
                      'px-4 py-2 rounded',
                      item.hasInvestigation === null
                        ? 'bg-gray-200'
                        : item.hasInvestigation
                        ? 'bg-green-600 text-white'
                        : 'bg-red-600 text-white',
                    ]"
                    @click="openInvestigationPopup(item)"
                  >
                    <div class="flex items-center justify-between w-full gap-2">
                      <Icon icon="mdi:magnify" />
                      <span>{{
                        $t(
                          "project.projectUser.components.referenceAndPayment.columns.investigation"
                        )
                      }}</span>
                      <Icon icon="mdi:chevron-right" />
                    </div>
                  </button>
                </template>
              </customTable>
            </div>
          </div>
        </div>
      </template>
    </sideModal>

    <ReferenceAndPaymentModals
      :showPopup.sync="showPopup"
      :popupType="popupType"
      :selectedItem="selectedItem"
      :projectId="$route.query.id"
      @reset="resetAll"
      @refresh="getAll"
    />
    <RejectModal
      ref="rejectModal"
      :item="selectedItem"
      @confirm="handleReject"
      @close="selectedItem = null"
    />
    <personelInformationModal ref="personelInformationModal" :Detail="selectedItem" v-if="canRender(13, 43)" />

  </div>
</template>

<script>
import customTable from "@/components/customTable.vue";
import {
  referenceAndPayment,
  shortList,
  projectConnect,
  personelInformation,
} from "@/networking/urlmanager";
import ReferenceAndPaymentModals from "./referenceAndPaymentModals.vue";
import asyncButton from "@/components/button.vue";
import RejectModal from "@/views/project/projectUser/components/shortlist/components/table/rejectModal.vue";
import personelInformationModal from "@/views/project/projectUser/components/shortlist/components/personelInformation/index.vue";
export default {
  name: "ReferenceIndex",
  components: {
    customTable,
    ReferenceAndPaymentModals,
    asyncButton,
    RejectModal,
    personelInformationModal,
    
  },
  data() {
    return {
      load: false,
      loading: false,
      items: [],
      currentPage: 0,
      pageCount: 1,
      totalCount: 0,
      columns: [
        {
          key: "fullname",
          label: this.$t(
            "project.projectUser.components.referenceAndPayment.columns.candidate"
          ),
          width: "220px",
        },
        {
          key: "state",
          label: this.$t(
            "project.projectUser.components.referenceAndPayment.columns.state"
          ),
          width: "150px",
        },
        {
          key: "reference",
          width: "170px",
        },
        {
          key: "payment",
          width: "170px",
        },
        {
          key: "investigation",
          width: "170px",
        },
      ],
      popupType: null,
      showPopup: false,
      selectedItem: null,
      infoFormLoad: false,
    };
  },
  methods: {
    async toggleSidebar() {
      this.$refs.referenceSide.show();
      setTimeout(async () => {
        await this.getAll();
      }, 50);
    },
    async getAll() {
      try {
        this.loading = true;
        const response = await this.axios.get(referenceAndPayment.getAll, {
          params: {
            page: this.currentPage,
            projectId: this.$route.query.id,
          },
        });

        this.items = response.data.data.detail || [];
        this.totalCount = response.data.data.totalCount || 0;
        this.pageCount = response.data.data.pageCount || 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getAll();
    },
    openReferencePopup(item) {
      this.selectedItem = item;
      this.popupType = "reference";
      this.showPopup = true;
    },
    openPaymentPopup(item) {
      this.selectedItem = item;
      this.popupType = "payment";
      this.showPopup = true;
    },
    openInvestigationPopup(item) {
      this.selectedItem = item;
      this.popupType = "investigation";
      this.showPopup = true;
    },
    resetAll() {
      this.showPopup = false;
      this.selectedItem = null;
      this.popupType = null;
    },

    deleteItem(item) {
      this.selectedItem = {
        cvId: item.cvId,
        projectId: this.$route.query.id,
        rowId: item.pcuId,
        stateId: item.stateId,
        fullname: item.fullname,
        contactEmail: item.email,
        id: item.shortlistId,
      };
      this.$refs.rejectModal.show();
    },
    async handleReject(payload) {
      this.loading = true;
      try {
        const response = await this.axios.post(
          shortList.setInadequate,
          payload
        );
        this.successMessage(response.data.message);
        this.loading = false;

        this.getAll();
        this.$emit("refresh", true);
      } catch (err) {
        console.log(err);
        this.loading = false;

        this.authController(err);
      } 
    },
    async handleContinueWithInadequate(item) {
      this.verifiedMessage(
        this.$t(
          "project.tableInterview.verifiedMessage.continueWithInadequate",
          { name: item.fullname }
        ),
        this.$t(
          "project.tableInterview.verifiedMessage.continueWithInadequateConfirm"
        ),
        this.$t(
          "project.tableInterview.verifiedMessage.continueWithInadequateDecline"
        )
      ).then((result) => {
        if (result.isConfirmed) {
          this.continueWithInadequate(item);
        }
      });
    },

    async continueWithInadequate(item) {
      this.loading = true;
      try {
        const res = await this.axios.put(projectConnect.revertInadequate, {
          projectId: this.$route.query.id,
          cvId: item.cvId,
          isShortlist: true,
        });
        this.successMessage(res.data.message);
        this.$emit("refresh", true);
        this.loading = false;
        this.getAll();
      } catch (error) {
        this.authController(error);
        this.loading = false;
      } finally {
      }
    },
    handleHireCandidate(item) {
      this.verifiedMessage(
        this.$t(
          "project.tableInterview.verifiedMessage.hireCandidate",
          { name: item.fullname }
        ),
        this.$t("project.tableInterview.verifiedMessage.hireCandidateConfirm"),
        this.$t("project.tableInterview.verifiedMessage.hireCandidateDecline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.hireCandidate(item);
        }
      });
    },
    async hireCandidate(item) {
      this.loading = true;
      try {
        const res = await this.axios.post(shortList.workHired, {
          projectId: this.$route.query.id,
          cvId: item.cvId, 
          fullname: item.fullname,
        });
        this.successMessage();
        this.$emit("refresh", true);
        this.loading = false;
        this.getAll();
      } catch (error) {
        this.authController(error);
        this.loading = false;
      } finally {
      }
    },
    
    stateName(val) {
      try {
        if (val == 1) {
          return {
            name: this.$t("project.tableInterview.stateName.waiting"),
            class: "",
          };
        } else if (val == 2) {
          return {
            name: this.$t("project.tableInterview.stateName.interviewSent"),
            class: "text-green-500",
          };
        } else if (val == 3) {
          return {
            name: this.$t("project.tableInterview.stateName.dateConfirmed"),
            class: "text-green-500",
          };
        } else if (val == 4) {
          return {
            name: this.$t("project.tableInterview.stateName.dateRejected"),
            class: "text-red-500",
          };
        } else if (val == 5) {
          return {
            name: this.$t("project.tableInterview.stateName.meetCreated"),
            class: "text-green-500",
          };
        } else if (val == 6) {
          return {
            name: this.$t("project.tableInterview.stateName.hired"),
            class: "text-green-500",
          };
        } else if (val == 7) {
          return {
            name: this.$t("project.tableInterview.stateName.inadequate"),
            class: "text-red-500",
          };
        } else if (val == 8) {
          return {
            name: this.$t("project.tableInterview.stateName.dismissed"),
            class: "text-red-500",
          };
        } else if (val == 9) {
          return {
            name: this.$t("project.tableInterview.stateName.referenceList"),
            class: "text-green-500",
          };
        } else {
          return { name: "", class: "" };
        }
      } catch {
        return { name: "", class: "" };
      }
    },
    showPersonelInformation(val) {
      this.selectedItem = val;
      this.$refs.personelInformationModal.show();
    },
    personelInformationMail(data) {
      this.verifiedMessage(
        data.fullname + ' '+
          this.$t(
            "project.tableInterview.verifiedMessage.candidateFormRequest"
          ),
        this.$t("project.tableInterview.verifiedMessage.confirm"),
        this.$t("project.tableInterview.verifiedMessage.decline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.infoFormLoad = true;
          this.loading = true;
          this.selectedIndex = data.id;
          this.axios
            .post(personelInformation.mail, {
              cvId: data.cvId,
              fullname: data.fullname,
              projectId: data.projectId,
              shortId: data.id,
              email: data.email,
            })
            .then((res) => {
              this.infoFormLoad = false;
              this.loading = false;
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              this.infoFormLoad = false;
              this.loading = false;
              this.authController(err);
            });
        }
      });
    },
  },
  created() {
    
    // Module 15 action checks
    this.handleReject = this.withModuleActionCheck(this.handleReject, 15, 45); // setInadequate
    this.hireCandidate = this.withModuleActionCheck(this.hireCandidate, 15, 46); // workHired
  },
};
</script>
