<template>
  <div class="relative inline-block text-left">
    <CustomDropdown :width="96">
      <template #toggle>
        <Icon icon="mdi:language" class="text-slate-300 text-xl"></Icon>
      </template>
      
      <div class="py-2 w-full" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div v-if="load" class="px-4 py-2 text-lg text-gray-700 flex gap-1 items-center justify-center">
          <i class="fas fa-spinner fa-spin"></i> 
        </div>
        <div v-else>
          <button
            class="block px-4 py-2 w-full text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="changeLanguage('tr')"
            :disabled="active === 'tr'"
            :class="{ 'bg-gray-100 text-gray-900': active === 'tr' }"
          >
            <span :class="{ 'text-slate-700': active === 'tr' }"> Türkçe </span>
          </button>
          <button
            class="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click="changeLanguage('en')"
            :disabled="active === 'en'"
            :class="{ 'bg-gray-100 text-gray-900': active === 'en' }"
          >
            <span :class="{ 'text-slate-700': active === 'en' }"> English </span>
          </button>
        </div>
      </div>
    </CustomDropdown>
  </div>
</template>

<script>
import { auth } from "@/networking/urlmanager";
import moment from 'moment'
import CustomDropdown from "@/components/customDropdown.vue";

export default {
  name: "globalLanguageSelect",
  components: {
    CustomDropdown
  },
  data() {
    return {
      active: this.$store.state.userData.locale,
      load: false
    };
  },
  computed: {
    activeLocale() {
      return this.$store.state.userData.locale;
    },
  },
  methods: {
    async changeLanguage(lang) {
      try {
        this.load = true
        this.axios.defaults.headers['Accept-Language'] = lang;
        await this.axios.post(auth.setLanguage, {
          language: lang,
        });
        this.$i18n.locale = lang;
        moment.locale(lang)
        this.active = lang;
        this.$store.commit("setLocale", lang);
        this.load = false
      } catch (err) {
        console.log(err)
        this.load = false
        this.authController(err);
      }
    }
  },
  created() {
    this.active = this.$store.state.userData.locale
  },
  watch: {
    activeLocale() {
      this.active = this.activeLocale;
    },
  },
};
</script>
