<template>
  <modal :name="`candidate-review${name ? '-' + name : ''}`" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '60%'"
    :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50">
      <h1 class="">{{ $t("project.detail.preInterviewDetail.title") }}</h1>
      <button @click="hide()" type="button">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <topBar @click="(r) => getAll(r)" :load="load" />

    <div class="px-3 py-3 relative">
      <customTable :items="List" :columns="columns" :loading="load" :showPagination="false" height="300px"
        :isSecondLine="true"
        variant="secondary" >
        <template #index="{ index }">
          <div>
            {{ index + 1 }}
          </div>
        </template>

        <template #actions="{ item, index }">
          <div class="flex gap-4 items-center h-full">
            <button @click="openOldAnswer(item, index)" v-if="item.originalAnswer"
              class="relative group flex justify-center">
              <i class="fa-solid fa-clock-rotate-left w-4"></i>
              <tooltip :title="$t('project.detail.preInterviewDetail.oldAnswerTooltip')"
                tipClass="z-50 w-[160px] !-right-1 !top-6" rotateClass="ml-[140px]" :top="true" />
            </button>
          </div>
        </template>

        <template #secondLine="{ item }">
          <transition name="slide-fade-top">
          <div class="flex flex-col gap-2 py-4 items-center justify-center w-full h-full" v-if="item?.secondline">
          <div class="flex w-full space-x-2">
            <span class="text-sm font-bold">
              {{ $t('project.detail.preInterviewDetail.oldAnswer') }}
            </span>
            <div class="text-sm">
              {{ item.originalAnswer }}
            </div>
          </div>
          <div class="flex w-full space-x-2">
            <span class="text-sm font-bold">
              {{ $t('project.detail.preInterviewDetail.similarity') }}
            </span>
            <div class="text-sm">
              {{ getPercentage(item.similarityStatus) }}
            </div>
            </div>
          </div>
        </transition>
        </template>
      </customTable>

      <div class="text-sm w-full my-4 p-4 border border-gray-100 shadow-md rounded-md">
        <p>
          <span  class="text-cvus-primary-light font-semibold">{{ $t("project.detail.preInterviewDetail.preInterviewScore") }}</span>
          {{ recomendedInterviewScore }}
        </p>
        <p>
          <span class="text-cvus-primary-light font-semibold">{{ $t("project.detail.preInterviewDetail.preInterviewNote")
          }}</span>
          <span>
            {{ recomendedReason }}
          </span>
        </p>
      </div>
      <div class="w-full border border-gray-100 pt-2 my-4 p-4 shadow-md rounded-md"
        v-if="Detail.stateId == 7 || Detail.stateId == 8 || Detail.stateId == 9">

        <div v-if="Detail.stateId < 8" class="mb-4">
          <div class="flex border-b border-gray-200">
            <button
              @click="activeTab = 'continue'"
              class="px-4 py-2 text-sm font-medium"
              :class="activeTab === 'continue' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'"
            >
              {{ $t('project.detail.preInterviewDetail.continue') }}
            </button>
            <button
              @click="activeTab = 'unsatisfactory'"
              class="px-4 py-2 text-sm font-medium"
              :class="activeTab === 'unsatisfactory' ? 'border-b-2 border-red-500 text-red-600' : 'text-gray-500 hover:text-gray-700'"
            >
              {{ $t('project.detail.preInterviewDetail.unsatisfactory') }}
            </button>
          </div>

          <!-- Continue Tab Content -->
          <div v-if="activeTab === 'continue'" class="mt-4">
            <form @submit.prevent="addShortList" v-if="canRender(12, 35)">
              <customInput
                v-model="shortlistComment"
                :title="$t('project.detail.preInterviewDetail.shortlistNote')"
                type="textarea"
                max="500"
                :placeholder="$t('project.detail.preInterviewDetail.shortlistComment')"
                :rows="4"
              />
              <div class="flex justify-end mt-4">
                <buttonItem
                  :load="saveLoad"
                  :disabled="indidateLoad"
                  :text="$t('project.detail.preInterviewDetail.addShortList')"
                  buttonClass="w-full"
                />
              </div>
            </form>
          </div>

          <!-- Unsatisfactory Tab Content -->
          <div v-if="activeTab === 'unsatisfactory'" class="mt-4">
            <form @submit.prevent="setInadequate" v-if="canRender(15, 45)">
              <div class="flex items-center gap-4 mb-4">
                <label class="flex items-center">
                  <input type="radio" v-model="inadequateType" value="predefined" class="mr-2">
                  {{ $t('project.detail.preInterviewDetail.predefinedNote') }}
                </label>
                <label class="flex items-center">
                  <input type="radio" v-model="inadequateType" value="custom" class="mr-2">
                  {{ $t('project.detail.preInterviewDetail.customNote') }}
                </label>
              </div>

              <div v-if="inadequateType === 'predefined'" class="mb-[30px] max-w-[300px] overflow-hidden">
                <customSelect
                  v-model="selectedInadequateId"
                  :options="inadequateList"
                  :title="$t('project.detail.preInterviewDetail.selectInadequate')"
                  :placeholder="$t('project.detail.preInterviewDetail.selectInadequate')"
                  :required="true"
                  :error-message="$t('project.detail.preInterviewDetail.selectRequired')"
                  ref="inadequateSelect"
                />
              </div>

              <customInput
                v-if="inadequateType === 'custom'"
                v-model="inadequateComment"
                :title="$t('project.detail.preInterviewDetail.note')"
                type="textarea"
                max="500"
                :placeholder="$t('project.detail.preInterviewDetail.yourComment')"
                :rows="4"
              />

              <div class="flex justify-end mt-4">
                <buttonItem
                  :load="indidateLoad"
                  :disabled="saveLoad"
                  type="button"
                  @click="setInadequate"
                  :text="$t('project.detail.preInterviewDetail.markUnsatisfactory')"
                  buttonClass="w-full bg-red-500"
                  containerBackground="bg-red-500"
                />
              </div>
            </form>
          </div>
        </div>

        <!-- View Only State -->
        <div v-if="Detail.stateId >= 8">
          <customInput
            :value="Detail.stateId == 8 ? Detail.inadequateNote : shortlistComment"
            :title="$t('project.detail.preInterviewDetail.note')"
            type="textarea"
            :disabled="true"
            max="500"
            :placeholder="$t('project.detail.preInterviewDetail.yourComment')"
            :rows="4"
          />
          <label class="text-red-500 text-sm font-bold">
            {{
              Detail.stateId == 8
                ? $t("project.detail.preInterviewDetail.markedInsufficient")
                : $t("project.detail.preInterviewDetail.addedShortList")
            }}
            <br />
            <span class="text-xs text-black">
              {{ Detail.stateId == 8 ? Detail.inadequateDate : Detail.addShortListDate | formatDate }}
            </span>
          </label>

          <!-- Add new button for continuing with inadequate candidate -->
          <div v-if="Detail.stateId == 8" class="mt-4">
            <buttonItem
              :load="continueWithInadequateLoad"
              :text="$t('project.detail.preInterviewDetail.continueWithInadequate')"
              buttonClass="w-full"
              @click="continueWithInadequate"
            />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import topBar from "./components/topBar.vue";
import customTable from "@/components/customTable.vue";
import tooltip from "@/components/tooltip.vue";
import customSelect from "@/components/customSelect.vue";

import {
  shortList,
  projectInterview,
  projectConnect,
  settingConfigration
} from "@/networking/urlmanager";

export default {
  name: "log-component",
  props: ["Detail", "projectId", "rowId","name"],
  components: {
    customInput,
    buttonItem,
    topBar,
    customTable,
    tooltip,
    customSelect
  },
  data() {
    return {
      search: "",
      List: [],
      load: false,
      indidateLoad: false,
      shortlistComment: "",
      inadequateComment: "",
      saveLoad: false,
      inadequateType: "predefined",
      selectedInadequateId: null,
      inadequateList: [],
      activeTab: "continue",
      submitted: false,
      continueWithInadequateLoad: false,
      recomendedInterviewScore: 0,
      recomendedReason: ""
    };
  },
  computed: {
    columns() {
      return [
        {
          key: 'index',
          label: '#',
          width: '80px',
          format: (_, __, index) => index + 1
        },
        {
          key: 'question',
          label: this.$t("project.detail.preInterviewDetail.question"),
          width: '300px',
          format: (value) => value ? value : this.$t("project.detail.preInterviewDetail.noQuestion")
        },
        {
          key: 'answer',
          label: this.$t("project.detail.preInterviewDetail.answer"),
          width: '300px',
          format: (value) => value ? value : this.$t("project.detail.preInterviewDetail.notAnswer")
        }
      ];
    }
  },
  created() {
    this.fetchInadequateList();
  },
  methods: {
    getPercentage(similarityStatus){
      if (Number(similarityStatus) === 1) {
        return '100%'
      }
      return (similarityStatus * 100).toFixed(2) + '%' || 0
    },
    show() {
      this.shortlistComment = "";
      this.inadequateComment = "";
      this.$modal.show(`candidate-review${this.name ? '-' + this.name : ''}`);
      setTimeout(() => {
        if (this.Detail.cvId > 0) this.getAll();
      }, 50);
    },
    hide() {
      this.$modal.hide(`candidate-review${this.name ? '-' + this.name : ''}`);
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll(type = 1) {
      this.load = true;
      this.List = [];
      this.axios.get(projectInterview.getQuestionAnswer, {
        params: {
          cvId: this.Detail.cvId,
          projectId: this.projectId,
          search: this.search,
          page: this.currentPage,
          rowId: this.Detail.id,
          type: type,
        },
      })
        .then((res) => {
          this.List = res.data.data.data;
          this.List.forEach(item => {
            item.secondline = false;
          });
          this.shortlistComment = res.data.data.comment;
          this.recomendedInterviewScore = res.data.data.recomendedInterviewScore;
          this.recomendedReason = res.data.data.recomendedReason;

          this.load = false;
        })
        .catch((err) => {
          this.authController(err);
          this.load = false;
        });
    },
    addShortList() {
      if (this.Detail.stateId >= 8) {
        this.warningMesage(
          this.$t("project.detail.preInterviewDetail.candidateNoAction")
        );
        return;
      }

      this.saveLoad = true;
      this.axios
        .post(shortList.add, {
          rowId: this.Detail.cvId,
          fullname: this.Detail.fullname,
          interviewScore: this.Detail.recomendedInterviewScore,
          interviewNote: this.shortlistComment ? this.shortlistComment : '',
          projectId: this.projectId,
          projectName: this.$route.query.projectName,
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.saveLoad = false;
          this.hide();
          this.$emit("refresh", true);
        })
        .catch((err) => {
          this.authController(err);
          this.saveLoad = false;
        });
    },
    async setInadequate() {
      if (this.Detail.stateId == 8) {
        this.warningMesage(
          this.$t(
            "project.detail.preInterviewDetail.candidateAlreadyInadequate"
          )
        );
        return;
      }

      if (this.inadequateType === 'custom' && this.inadequateComment?.length < 5) {
        this.warningMesage(
          this.$t("project.detail.preInterviewDetail.charactersLong")
        );
        return;
      }

      if (this.inadequateType === 'predefined' && !this.selectedInadequateId) {
        this.warningMesage(
          this.$t("project.detail.preInterviewDetail.selectInadequateMessage")
        );
        return;
      }

      this.indidateLoad = true;
      this.submitted = true;
      try {
        const res = await this.axios.post(projectConnect.setInadequate, {
          rowId: this.Detail.id,
          cvId:this.Detail.cvId,
          inadequateNote: this.inadequateType === 'custom' ? this.inadequateComment : undefined,
          inadequateId: this.inadequateType === 'predefined' ? this.selectedInadequateId : undefined,
          fullname: this.Detail.fullname,
          projectName: this.$route.query.projectName,
          projectId: this.$route.query.id,
          email: this.Detail.contactEmail
        });

        this.successMessage(res.data.message);
        this.indidateLoad = false;
        this.submitted = false;

        this.hide();
        this.$emit("refresh", true);
      } catch (error) {
        console.log(error);
        this.authController(error);
        this.indidateLoad = false;
        this.submitted = false;
      }
    },
    openOldAnswer(item, index) {
      if (item.secondline) {
        const newList = this.List.map(item => {
          if (item.id === this.List[index].id) {
            item.secondline = false;
          }
          return item;
        });
        this.List = newList;
      } else {
        const newList = this.List.map(item => {
          if (item.id === this.List[index].id) {
            item.secondline = true;
          }
          return item;
        });
        this.List = newList;

      }
    },
    async fetchInadequateList() {
      try {
        const response = await this.axios.get(settingConfigration.getInadequateList);
        this.inadequateList = (response.data.data || []).map(item => ({
          id: item.id,
          name: item.inadequateMessage
        }));
        if (this.inadequateList.length > 0) {
          this.selectedInadequateId = this.inadequateList[0].value;
        }
      } catch (error) {
        console.error("Error fetching inadequate messages:", error);
      }
    },
    async continueWithInadequate() {
      this.continueWithInadequateLoad = true;
      try {
        const res = await this.axios.put(projectConnect.revertInadequate, {
          rowId: this.Detail.id
        });
        this.successMessage(res.data.message);
        this.hide();
        this.$emit("refresh", true);
      } catch (error) {
        this.authController(error);
      } finally {
        this.continueWithInadequateLoad = false;
      }
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
