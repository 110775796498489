<template>
  <div class="relative" ref="dropdownContainer">
    <!-- Dropdown Toggle Button -->
    <div @click="toggleDropdown" ref="toggleButton" class="cursor-pointer flex items-center justify-center">
      <slot name="toggle">
        <button class="flex items-center gap-1">
          <slot name="toggle-icon">
            <i class="fas fa-chevron-down transition-transform" :class="{ 'rotate-180': isOpen }"></i>
          </slot>
          <slot name="toggle-text">Toggle</slot>
        </button>
      </slot>
    </div>

    <!-- Overlay for capturing clicks outside when dropdown is open -->
    <Teleport to="body" v-if="isOpen && closeOnClickOutside">
      <div class="fixed inset-0 z-[9998]" @click.self="closeDropdown"></div>
    </Teleport>

    <!-- Dropdown Content with Teleport -->
    <Teleport to="body">
      <transition name="dropdown">
        <div 
          v-if="isOpen" 
          class="fixed z-[9999] dropdown-content"
          :style="dropdownStyle"
          ref="dropdownElement"
        >
          <!-- Triangle pointer - shows above or below dropdown based on direction -->
          <div 
            class="dropdown-arrow absolute w-3 h-3 bg-white transform  rotate-45 border"
            :class="[
              !isOpeningUpwards ? 'bottom-arrow border-b-0 border-r-0 bottom-0 ' : 'top-arrow border-t-0 border-l-0 top-0'
            ]"
            :style="[arrowStyle, { backgroundColor: arrowColor }]"
          ></div>
          <!-- Main dropdown content -->
          <div 
            class="bg-white border border-gray-200 rounded shadow-lg overflow-y-auto w-full h-full"
            :class="[
              { 'max-h-[150px]': size === 'sm' },
              { 'max-h-[200px]': size === 'md' },
              { 'max-h-[250px]': size === 'lg' }
            ]"
          >
            <slot></slot>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  name: 'CustomDropdown',
  components: {
    Teleport,
  },
  props: {
    size: {
      type: String,
      default: 'md',
      validator: value => ['sm', 'md', 'lg'].includes(value)
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: 120
    },
    maxHeight: {
      type: [String, Number],
      default: null
    },
    alignment: {
      type: String,
      default: 'auto',
      validator: value => ['left', 'right', 'center', 'auto'].includes(value)
    },
    showArrow: {
      type: Boolean,
      default: true
    },arrowColor: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      isOpen: false,
      dropdownStyle: {},
      arrowStyle: {},
      isOpeningUpwards: false
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.updateDropdownPosition()
        })
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
    
    closeDropdown() {
      this.isOpen = false
      this.$emit('close')
    },
    
    updateDropdownPosition() {
      const buttonEl = this.$refs.toggleButton
      if (!buttonEl) return

      const rect = buttonEl.getBoundingClientRect()
      
      // Calculate dropdown dimensions
      let dropdownHeight = 200
      if (this.size === 'sm') dropdownHeight = 150
      if (this.size === 'lg') dropdownHeight = 250
      if (this.maxHeight) dropdownHeight = parseInt(this.maxHeight)
      
      const dropdownWidth = this.width ? this.width : rect.width
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      const spaceBelow = windowHeight - rect.bottom
      const spaceAbove = rect.top
      
      // Calculate horizontal position to handle screen overflow
      let leftPosition = rect.left - 10
      
      // Apply alignment based on user preference
      if (this.alignment === 'right' || (this.alignment === 'auto' && leftPosition + parseInt(dropdownWidth) > windowWidth - 10)) {
        // Align to right edge of toggle button
        leftPosition = rect.right - parseInt(dropdownWidth) + 10
      } else if (this.alignment === 'center') {
        // Center dropdown relative to toggle button
        leftPosition = rect.left + (rect.width / 2) - (parseInt(dropdownWidth) / 2)  - 10
      }
      
      // Safety check to prevent offscreen positioning
      if (leftPosition + parseInt(dropdownWidth) > windowWidth - 10) {
        leftPosition = Math.max(10, windowWidth - parseInt(dropdownWidth) - 10);
      }
      if (leftPosition < 10) {
        leftPosition = 10;
      }

      this.dropdownStyle = {
        position: 'fixed',
        top: `${rect.bottom + 4}px`,
        left: `${leftPosition}px`,
        width: typeof dropdownWidth === 'number' ? `${dropdownWidth}px` : dropdownWidth,
        visibility: 'hidden'
      }

      // Set initial arrow positioning - calculate relative to the toggle button center
      const arrowLeftPos = Math.min(
        Math.max(
          rect.left + (rect.width / 2) - leftPosition - 6, 
          12
        ), 
        parseInt(dropdownWidth) - 12
      )
      
      this.arrowStyle = {
        left: `${arrowLeftPos - 10}px`,
        // Adjust margin to eliminate gap
        marginTop: !this.isOpeningUpwards ? null : '-15px',
        marginBottom: !this.isOpeningUpwards ? '-15px' : null
      }

      this.$nextTick(() => {
        const dropdownEl = this.$refs.dropdownElement
        if (!dropdownEl) return

        const actualHeight = Math.min(dropdownEl.scrollHeight, dropdownHeight)
        const openUpwards = spaceBelow < actualHeight && spaceAbove > spaceBelow
        this.isOpeningUpwards = openUpwards

        this.dropdownStyle = {
          position: 'fixed',
          top: openUpwards ? `${rect.top - actualHeight - 4}px` : `${rect.bottom + 4}px`,
          left: `${leftPosition}px`,
          width: typeof dropdownWidth === 'number' ? `${dropdownWidth}px` : dropdownWidth,
          maxHeight: `${dropdownHeight}px`,
          visibility: this.showArrow ? 'visible' : 'hidden'
        }

        // Update arrow position based on opening direction
        const arrowLeftPos = Math.min(
          Math.max(
            rect.left + (rect.width / 2) - leftPosition - 10, 
            12
          ), 
          parseInt(dropdownWidth) - 12
        )
        
        this.arrowStyle = {
          left: `${arrowLeftPos }px`,
          // Adjust margin to eliminate gap
          marginTop: !openUpwards ? null : '-6px',
          marginBottom: !openUpwards ? '-6px' : null,
          visibility: this.showArrow ? 'visible' : 'hidden',
          zIndex: 10000
        }

        if (openUpwards) {
          this.dropdownStyle.transformOrigin = 'bottom'
        } else {
          this.dropdownStyle.transformOrigin = 'top'
        }
      })
    },
    
    handleScroll() {
      if (this.isOpen) {
        this.updateDropdownPosition()
      }
    },
    
    handleResize() {
      if (this.isOpen) {
        this.updateDropdownPosition()
      }
    }
  }
}
</script>

<style scoped>
/* Animation for dropdown */
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}

/* Triangle arrow styling */
.dropdown-arrow {
  border-color: #e2e8f0;
  z-index: 1;
}

.dropdown-content {
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

/* Remove border to create clean dropdown styling */
.dropdown-content > div {
  position: relative;
  z-index: 2;
}

/* Scrollbar Styling */
.dropdown-content .overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.dropdown-content .overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.dropdown-content .overflow-y-auto::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.dropdown-content .overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
</style> 