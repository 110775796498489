<template>
  <div class="relative group" ref="profile">
    <button class="flex items-center gap-3" @click.stop="toggleDropdown">
      <div class="rounded-full p-2 profile-border-shadow bg-cvus-primary ">
      <Icon icon="mdi:account" class="text-white text-2xl" />
    </div>
   
    </button>
    <div
      v-show="isDropdownVisible"
      tabindex="0"
      class="absolute bg-[#fdfdfe] block -mr-5 w-40 right-0 textcol mt-6 shadow-md rounded"
      ref="dropdown"
    >
    <div class="text-left text-black p-2">
        <h1 class="text-base capitalize font-bold">
          {{
            this.$store.state.userData.firstName +
            " " +
            this.$store.state.userData.lastName
          }}
        </h1>
        <span class="text-sm text-gray-500">
          {{ this.$store.state.userData.role }}
        </span>
      </div>
      <!-- dropdown content -->
      
      <div class="relative z-[998]">
        <button
          @click="clearAll()"
          class="w-full py-2 text-sm text-left px-5 text-red-500 hover:bg-red-50 rounded"
        >
          <h1>
            <i class="fa-solid fa-right-from-bracket mr-2"></i
            >{{ $t("profile.header.signOut") }}
          </h1>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "profile-components",
  data() {
    return {
      isDropdownVisible: false,
      profile: null,
      dropdown: null,
    };
  },
 
  methods: {
    clearAll() {
      this.verifiedMessage(
        this.$t("profile.header.verifiedMessage"),
        this.$t("profile.header.confirmButtonText")
      ).then((res) => {
        if (res.isConfirmed) {
          this.signOut();
        }
      });
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    handleClickOutside(e) {
      if (this.dropdown && !this.dropdown.contains(e.target)) {
        this.isDropdownVisible = false;
      }
    },
  },
  mounted() {
    this.profile = this.$refs.profile;
    this.dropdown = this.$refs.dropdown;
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<!--  <router-link to="/profile" class="w-full block py-2 text-sm text-left px-5 hover:bg-slate-50">
        <h1><i class="fa-solid fa-user mr-2"></i>Profil</h1>
      </router-link>
      <button class="w-full text-sm py-2 text-left px-5 hover:bg-slate-50">
        <h1><i class="fa-solid fa-user-pen mr-2"></i>Profil Düzenle</h1>
      </button>-->
      <style>
      .profile-border-shadow {
        box-shadow: 0 4px 8px 0 rgba(15, 15, 53, 0.3), inset 0 0 4px 0 rgba(240, 225, 255, 0.2);
        transition: all 0.3s ease;
      }
      .profile-border-shadow:hover {
        box-shadow: 0 4px 8px 0 rgba(15, 15, 53, 0.6), inset 0 0 4px 0 rgba(240, 225, 255, 0.2);

      }
      </style>
      