<template>
    <div class="[&>*>label]:!text-black w-full flex px-2">
        <customSelect 
            :options="companyOptions" 
            :value="selectedCompanyId"
            :loading="companiesLoading"
            @input="handleCompanySelect"
            :placeholder="$t('globalComponents.sideBar.companySelect')"
            :label="$t('globalComponents.sideBar.company')"
        />
    </div>
</template>

<script>
import customSelect from "@/components/customSelect.vue";
import { mapState, mapMutations } from 'vuex';
import { users } from '@/networking/urlmanager';

export default {
    components: {
        customSelect,
    },
    data() {
        return {
            companyOptions: [],
            companiesLoading: false,
            companyPage: 0,
            companySearch: '',
            companyTotalPages: 0,
            selectedCompanyId: null
        }
    },
    computed: {
        ...mapState({
            activeCompany: state => state.activeCompany
        })
    },
    methods: {
        ...mapMutations(['setActiveCompany']),
        getCompanies() {
            this.companiesLoading = true;
            this.axios
                .get(users.getCompaniesAll, {
                    params: {
                        page: this.companyPage,
                        search: this.companySearch
                    }
                })
                .then((res) => {
                    if (res.data && res.data.data) {
                        const { detail, pageCount } = res.data.data;
                        this.companyTotalPages = pageCount;
                        
                        if (this.companyPage === 0) {
                            this.companyOptions = detail.map(company => ({
                                id: company.id,
                                name: company.name + (` - ${company.type == 1  ? this.$t('globalComponents.sideBar.companyTypes.holding') : this.$t('globalComponents.sideBar.companyTypes.company')}`),
                                type: company.type,
                                originalName: company.name
                            }));
                        } else {
                            this.companyOptions = [
                                ...this.companyOptions,
                                ...detail.map(company => ({
                                    id: company.id,
                                    name: company.name,
                                    type: company.type,
                                    originalName: company.name
                                }))
                            ];
                        }
                        
                        // After loading options, try to apply the active company
                        this.$nextTick(() => {
                            this.syncWithStoreCompany();
                        });
                    }
                })
                .catch((err) => {
                    this.authController(err);
                })
                .finally(() => {
                    this.companiesLoading = false;
                });
        },
        handleCompanySelect(companyId) {
            const oldValue = this.selectedCompanyId;
            this.selectedCompanyId = companyId;
            
            const selectedCompany = this.companyOptions.find(company => company.id === companyId);
            if (selectedCompany) {
                this.setActiveCompany({
                    id: selectedCompany.id,
                    name: selectedCompany.name,
                    originalName: selectedCompany.originalName
                });
            }
            if(oldValue != companyId){
                this.$router.push('/');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        },
        syncWithStoreCompany() {
            // If there's an active company in the store and we have options available
            if (this.activeCompany?.id && this.companyOptions.length > 0) {
                const company = this.companyOptions.find(c => c.id === this.activeCompany.id);
                if (company) {
                    // Set local component state
                    this.selectedCompanyId = company.id;
                } else {
                    // Check if there's a holding company (type 1) that can be selected as default
                    const holdingCompany = this.companyOptions.find(c => c.type === 1);
                    if (holdingCompany) {
                        this.selectedCompanyId = holdingCompany.id;
                        this.setActiveCompany({
                            id: holdingCompany.id,
                            name: holdingCompany.name,
                            originalName: holdingCompany.originalName
                        });
                    } else {
                        this.selectedCompanyId = null;
                        this.setActiveCompany({
                            id: null,
                            name: "",
                            originalName: ""
                        });
                    }
                }
            }else{
                const holdingCompany = this.companyOptions.find(c => c.type === 1);
                    if (holdingCompany) {
                        this.selectedCompanyId = holdingCompany.id;
                        this.setActiveCompany({
                            id: holdingCompany.id,
                            name: holdingCompany.name,
                            originalName: holdingCompany.originalName
                        });
                    } else {
                        this.selectedCompanyId = null;
                        this.setActiveCompany({
                            id: null,
                            name: "",
                            originalName: ""
                        });
                    }
            }
        }
    },
    created() {
       
        this.getCompanies();
    },
    
}
</script>