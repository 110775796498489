import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import moment from "moment";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userData: {
      userId: 0,
      isAuth: false,
      token: "",
      isExtract: 1,
      companyName: "",
      companyId: null,
      email: "",
      firstName: "",
      lastName: "",
      tourModuleId: [],
      companyType: 0,
      locale: "tr",
      isRobot: false,
      authority: null,
      userPermissions: [],
      role: null,
    },
    isMeetingBar: false,
    isSidebar: true,
    contactCompany: {
      contact: null,
      personal: [],
    },
    email: "",
    rememberMe: 1,
    resumeFilter: [],
    exportRessume: {
      language: 1,
      hidenList: [],
    },
    /*---------------------------- General --------------------------------*/

    /*---------------------------- integration --------------------------------*/
    integration: {
      defaultService: 1,
      microsoft: {
        token: "",
        date: "",
        refreshToken: "",
        isRefresh: true,
      },
      google: {
        token: "",
        date: "",
        refreshToken: "",
        isRefresh: true,
      },
    },
    /*---------------------------- integration --------------------------------*/

    /*---------------------------- Ressume --------------------------------*/
    extractDetail: {
      fullname: "",
      position: "",
      biography: "",
      dateBirth: "",
      workexperience: [],
      schoolAndEducation: [],
      languages: [],
      skills: [],
      computerSkills: [],
      projectsPublications: [],
    },
    invalidFiles: [],
    projectList: [],
    extractCvList: [],
    languagesList: [],
    extractPure: "",
    cvFile: null,
    originalFile: "",
    interviewId: 0,

    /*---------------------------- Ressume --------------------------------*/

    /*---------------------------- Project --------------------------------*/

    project: {
      activeStep: 1,
      load: false,
      advertState: false,
      advertLoad: false,
      stateId: null,
      changeMessage: "",
      stepData: {
        stepOne: {
          companyName: "",
          projectName: "",
          positionList: {},
          priority: "",
          endDate: "",
          adType: "",
          recruitmentCount: "",
          workingModel: "",
          isCompany: 2,
          isCompanyControl: 2,
          positionLanguage: [],
          advisors: [],
        },

        stepTwo: {
          educationType: [],
          institutionList: [],
          departmentList: [],
          languageList: [],
          experienceDuration: "",
          cityList: [],
        },

        stepThree: {
          isDescription: true,
          note: "",
          keywords: [],
          description: "",
          innovations: [],
          advert: "",
          advLanguage: [],
        },
      },
    },

    /*---------------------------- Company --------------------------------*/
    activeCompany: {
      id: null,
      name: "",
      originalName: ""
    },

  },

  getters: {
    /**
     * Check if user has permission for a specific module action
     */
    hasPermission: (state) => (moduleId, actionId) => {
      // If user has no permissions yet, return false
      if (!state.userData.userPermissions || state.userData.userPermissions.length === 0) {
        return false;
      }

      // Check if user has this specific permission
      return state.userData.userPermissions.some(permission =>
        permission.moduleId === moduleId &&
        permission.actions.some(action => action.id === actionId)
      );
    },

    /**
     * Get all actions for a specific module
     */
    getModuleActions: (state) => (moduleId) => {
      // Find the module in user permissions
      const module = state.userData.userPermissions.find(m => m.moduleId === moduleId);
      return module ? module.actions : [];
    },

    /**
     * Process project list
     */
    processedProjectList: (state) => {
      return {
        defaultService: state.integration.defaultService,
        interviewId: state.interviewId,
        stateId: (state) => state.stateId,
        keywords: state.project.stepData.stepThree.keywords,
        innovations: state.project.stepData.stepThree.innovations,
        priority: state.project.stepData.stepOne.priority,
        projectName: state.project.stepData.stepOne.projectName,
        positionList: state.project.stepData.stepOne.positionList,
        companyName: state.project.stepData.stepOne.companyName,
        endDate: state.project.stepData.stepOne.endDate,
        experienceDuration: state.project.stepData.stepTwo.experienceDuration,
        educationType: state.project.stepData.stepTwo.educationType,
        institutionList: state.project.stepData.stepTwo.institutionList,
        departmentList: state.project.stepData.stepTwo.departmentList,
        advert: state.project.stepData.stepThree.advert,
        adType: state.project.stepData.stepOne.adType,
        workingModel: state.project.stepData.stepTwo.workingModel,
        description: state.project.stepData.stepThree.description,
        cityList: state.project.stepData.stepTwo.cityList,
        recruitmentCount: state.project.stepData.stepOne.recruitmentCount,
        languageList: state.project.stepData.stepTwo.languageList,
        positionLanguage: state.project.stepData.stepThree.positionLanguage,
        advLanguage: state.project.stepData.stepThree.advLanguage,
        refreshLoad: false,
      };
    }
  },

  mutations: {
    setDefaultService(state, val) {
      state.integration.defaultService = val;
    },
    setInterviewId(val) {
      state.interviewId = val;
    },
    /*---------------------------- General --------------------------------*/
    signIn(state, data) {
      state.userData.userId = data.userId;
      state.userData.email = data.email;
      state.userData.companyName = data.companyName;
      state.userData.companyId = data.companyId;
      state.userData.firstName = data.firstName;
      state.userData.lastName = data.lastName;
      state.userData.token = data.token;
      state.userData.isExtract = data.isExtract;
      state.userData.isAuth = true;
      state.userData.companyType = data.companyType;
      state.userData.authority = data.authority;
      (state.userData.tourModuleId = Array.isArray(data.tourModuleId)
        ? data.tourModuleId
        : []);
      state.integration.defaultService = data.defaultProvide || 1;
      state.userData.role = data.roles?.[0]?.name || null;

      if (data.roles && data.roles.length > 0) {
        const userRole = data.roles[0];

        if (userRole.moduleList && userRole.moduleList.length > 0) {
          state.userData.userPermissions = userRole.moduleList.map(module => ({
            moduleId: module.moduleId,
            moduleName: module.moduleName,
            actions: module.actions
          }));
        } else {
          state.userData.userPermissions = [];
        }
      } else {
        state.userData.userPermissions = [];
      }
    },
    setRole(state, data) {
      if (data && data?.length > 0) {
        const userRole = data[0];

        if (userRole.moduleList && userRole.moduleList?.length > 0) {
          state.userData.userPermissions = userRole.moduleList.map(module => ({
            moduleId: module.moduleId            ,
            moduleName: module.moduleName,
            actions: module.actions
          }));
        } else {
          state.userData.userPermissions = [];
        }
      } else {
        state.userData.userPermissions = [];
      }
    },
    setLocale(state, locale) {
      state.userData.locale = locale;
    },
    setIsRobot(state, val) {
      state.userData.isRobot = val;
    },
    setTourModule(state, val) {
      state.userData.tourModuleId.push(val);
    },
    setCompanyName(state, val) {
      state.userData.companyName = val;
    },
    setMeetingBar(state, val) {
      state.isMeetingBar = val;
    },
    rememberMe(state, val) {
      (state.email = val), (state.rememberMe = 2);
    },
    setIsSidebar(state, val) {
      state.isSidebar = val;
    },
    signOut(state) {
      state.userData = {};
      state.userData.token = "";
      state.userData.isAuth = false;
      state.activeCompany = {
        id: null,
        name: "",
      };
    },
    resumeFilter(state, val) {
      state.resumeFilter = val;
    },
    setExportRessume(state, val) {
      state.exportRessume.language = val.isLanguage;
      state.exportRessume.hidenList = val.hidenList;
    },

    /*---------------------------- General --------------------------------*/

    /*---------------------------- integration --------------------------------*/
    setIntegrationMicrosoft(state, val) {
      state.integration.microsoft.token = val.token;
      state.integration.microsoft.date = moment().add(
        "seconds",
        val.expires_in
      );
      state.integration.microsoft.refreshToken = val.refresh_token;
    },
    setIntegrationGoogle(state, val) {
      state.integration.google.token = val?.access_token;
      state.integration.google.date = moment(val.expiry_date);
      state.integration.google.refreshToken = val?.refresh_token;
    },
    setIsMicrosoftRefresh(state, val) {
      state.integration.microsoft.isRefresh = val;
    },
    setIsGoogleRefresh(state, val) {
      state.integration.google.isRefresh = val;
    },
    /*---------------------------- integration --------------------------------*/

    /*---------------------------- Ressume --------------------------------*/

    clearExtractDetail(state) {
      state.extractDetail = {
        fullname: "",
        position: "",
        biography: "",
        dateBirth: "",
        workexperience: [],
        schoolAndEducation: [],
        languages: [],
        skills: [],
        computerSkills: [],
        projectsPublications: [],
      };
    },
    changeActiveExtractDetail(state, language) {
      state.extractDetail = JSON.parse(
        state.languagesList.find((r) => r.language == language).detail
      );
    },
    setLanguageList(state, val) {
      const findIndex = state.languagesList.findIndex(
        (r) => r.language == val.language
      );
      if (findIndex < 0) {
        state.languagesList.push({
          language: val.language,
          detail: val.detail,
        });
      }
    },
    deleteResumeFilter(state) {
      state.resumeFilter = [];
    },
    clearLanguageList(state) {
      state.languagesList = [];
    },

    extractCvList(state, val) {
      state.extractCvList = val;
    },

    setInvalidFiles(state, val) {
      state.invalidFiles = val;
    },
    viewCvList(state, val) {
      state.extractCvList[val] = 2;
    },
    extractCvListDelete(state) {
      state.extractCvList = [];
    },
    deleteExtractCv(state, val) {
      state.extractCvList.splice(val, 1);
    },
    setCvOriginalFile(state, val) {
      state.originalFile = val;
    },
    setContactCompany(state, val) {
      state.contactCompany.contact = val;
    },
    setContactPersonal(state, val) {
      state.contactCompany.personal = val;
    },
    setInformation(state, val) {
      state.extractDetail = val;
    },
    setCvExtractId(state, val) {
      state.extractId = val;
    },
    setExtractState(state, val) {
      state.userData.isExtract = val;
    },

    setProjectList(state, val) {
      if (val.length > 0) {
        state.projectList = val;
      } else state.projectList = [];
    },
    setProjectListDelete(state, val) {
      const projectIndex = state.projectList.findIndex(
        (project) => project.id == val
      );
      if (projectIndex !== -1) {
        state.projectList.splice(projectIndex, 1);
      }
    },
    setExtractDetail(state, val) {
      state.extractDetail = val;
    },
    setCvFile(state, val) {
      state.cvFile = val;
    },
    setExtractPure(state, val) {
      state.extractPure = val;
    },
    setIsExtract(state, val) {
      state.userData.isExtract = val;
    },
    // change item detail
    setPersonalInformation(state, val) {
      state.extractDetail.dateBirth = val.dateBirth;
      state.extractDetail.formatDateBirth = val.formatDateBirth;
      state.extractDetail.city = val.city;
      state.extractDetail.country = val.country;
      state.extractDetail.driverLicense = val.driverLicense;
      state.extractDetail.placeBirth = val.placeBirth;
      state.extractDetail.email = val.email;
    },
    setEditPersonalInformation(state, val) {
      if (val.id == 1)
        state.extractDetail.dateBirth = val.value
          ? moment(val.value).format("YYYY.MM.DD")
          : "";
      else if (val.id == 1) state.extractDetail.formatDateBirth = val.value;
      else if (val.id == 2) state.extractDetail.country = val.value;
      else if (val.id == 3) state.extractDetail.city = val.value;
      else if (val.id == 6) state.extractDetail.driverLicense = val.value;
      else if (val.id == 4) state.extractDetail.placeBirth = val.value;
      else if (val.id == 5) state.extractDetail.email = val.value;
    },
    editProfileInformation(state, val) {
      state.extractDetail.fullname = val.fullname;
      state.extractDetail.position = val.position;
      state.extractDetail.biography = val.biography;
    },

    // work experience
    setWorkExperience(state, val) {
      state.extractDetail.workexperience = val;
    },
    deleteExperience(state, val) {
      state.extractDetail.workexperience.splice(val, 1);
    },
    addExperience(state, val) {
      state.extractDetail.workexperience.push(val);
    },
    editExperience(state, val) {
      state.extractDetail.workexperience[val.index] = val.list;
    },

    //Contact
    setContact(state, val) {
      state.extractDetail.contactInformation = val;
    },
    addContact(state, val) {
      state.extractDetail.contactInformation.push(val);
    },
    editContact(state, val) {
      state.extractDetail.contactInformation[val.index] = val.list;
    },
    contactDelete(state, val) {
      state.extractDetail.contactInformation.splice(val, 1);
    },

    // education
    setSchoolAndEducation(state, val) {
      state.extractDetail.schoolAndEducation = val;
    },
    deleteEducation(state, val) {
      state.extractDetail.schoolAndEducation.splice(val, 1);
    },
    addSchool(state, val) {
      if (Array.isArray(state.extractDetail.schoolAndEducation)) {
        state.extractDetail.schoolAndEducation.push(val);
      } else {
        state.extractDetail.schoolAndEducation = [];
        state.extractDetail.schoolAndEducation.push(val);
      }
    },
    editSchool(state, val) {
      state.extractDetail.schoolAndEducation[val.index] = val.list;
    },

    // languages
    setLanguages(state, val) {
      state.extractDetail.languages = val;
    },
    setLanguageLevel(state, val) {
      state.extractDetail.languages[val.index].level = val.value;
    },
    deleteLanguage(state, val) {
      state.extractDetail.languages.splice(val, 1);
    },
    addLanguage(state, val) {
      if (Array.isArray(state.extractDetail.languages)) {
        state.extractDetail.languages.push(val);
      } else {
        state.extractDetail.languages = [];
        state.extractDetail.languages.push(val);
      }
    },
    editLanguage(state, val) {
      state.extractDetail.languages[val.index] = val.list;
    },

    // skills
    setSkils(state, val) {
      state.extractDetail.skills = val;
    },
    deleteSkill(state, val) {
      state.extractDetail.skills.splice(val, 1);
    },
    addSkils(state, val) {
      if (Array.isArray(state.extractDetail.skills)) {
        state.extractDetail.skills.push(val);
      } else {
        state.extractDetail.skills = [];
        state.extractDetail.skills.push(val);
      }
    },
    editSkils(state, val) {
      state.extractDetail.skills[val.index] = val.list;
    },

    //referance
    setReferences(state, val) {
      state.extractDetail.references = val;
    },

    //computer skills
    setComputerSkills(state, val) {
      state.extractDetail.computerSkills = val;
    },
    editComputerSkils(state, val) {
      state.extractDetail.computerSkills[val.index] = val.list;
    },
    addComputerSkils(state, val) {
      if (Array.isArray(state.extractDetail.computerSkills)) {
        state.extractDetail.computerSkills.push(val);
      } else {
        state.extractDetail.computerSkills = [];
        state.extractDetail.computerSkills.push(val);
      }
    },
    deleteComputerSkill(state, val) {
      state.extractDetail.computerSkills.splice(val, 1);
    },

    //CourseCertificate
    setSeminarAndCourse(state, val) {
      state.extractDetail.seminarAndCourse = val;
    },
    addCourseCertificate(state, val) {
      if (Array.isArray(state.extractDetail.seminarAndCourse)) {
        state.extractDetail.seminarAndCourse.push(val);
      } else {
        state.extractDetail.seminarAndCourse = [];
        state.extractDetail.seminarAndCourse.push(val);
      }
    },
    editCourseCertificate(state, val) {
      state.extractDetail.seminarAndCourse[val.index] = val.list;
    },
    deleteCourseCertificate(state, val) {
      state.extractDetail.seminarAndCourse.splice(val, 1);
    },

    // ProjectsPublications
    setProjectsPublications(state, val) {
      state.extractDetail.projectsPublications = val;
    },
    addProject(state, val) {
      if (Array.isArray(state.extractDetail.projectsPublications)) {
        state.extractDetail.projectsPublications.push(val);
      } else {
        state.extractDetail.projectsPublications = [];
        state.extractDetail.projectsPublications.push(val);
      }
    },
    editProject(state, val) {
      state.extractDetail.projectsPublications[val.index] = val.list;
    },
    deleteProject(state, val) {
      state.extractDetail.projectsPublications.splice(val, 1);
    },

    /*---------------------------- Ressume --------------------------------*/

    /*---------------------------- Project --------------------------------*/

    SET_STEP_ONE_DATA(state, data) {
      state.project.stepData.stepOne = {
        ...state.project.stepData.stepOne,
        ...data,
      };
    },
    SET_STEP_TWO_DATA(state, data) {
      state.project.stepData.stepTwo = {
        ...state.project.stepData.stepTwo,
        ...data,
      };
    },
    SET_STEP_THREE_DATA(state, data) {
      state.project.stepData.stepThree = {
        ...state.project.stepData.stepThree,
        ...data,
      };
    },
    SET_ADVERT_STATE(state, advertState) {
      state.project.advertState = advertState;
    },

    SET_PROJECT_ACTIVE_STEP(state, step) {
      state.project.activeStep = step;
    },

    SET_LOAD_STATE(state, load) {
      state.project.load = load;
    },

    SET_ADVERT_LOAD_STATE(state, load) {
      state.project.advertLoad = load;
    },

    SET_CHANGED_STATE(state, message) {
      state.project.changeMessage = message;
    },
    setStateId(state, id) {
      state.project.stateId = id;
    },
    setActiveCompany(state, val) {
      state.activeCompany = val;
    },
  },
  actions: {
    resetProjectData({ commit }) {
      commit("SET_STEP_ONE_DATA", {
        companyName: "",
        projectName: "",
        positionList: {},
        priority: "",
        endDate: "",
        adType: "",
        recruitmentCount: "",
        workingModel: "",
        isCompany: 2,
        isCompanyControl: 2,
        positionLanguage: [],
        advisors: [],
      });
      commit("SET_STEP_TWO_DATA", {
        educationType: [],
        institutionList: [],
        departmentList: [],
        languageList: [],
        experienceDuration: "",
        cityList: [],
      });
      commit("SET_STEP_THREE_DATA", {
        keywords: [],
        advert: false,
        innovations: [],
        description: "",
        evaluationCriteria: "",
        note: "",
        isDescription: false,
        positionLanguage: [],
        advLanguage: [],
      });
      commit("SET_ADVERT_STATE", false);
      commit("SET_PROJECT_ACTIVE_STEP", 1);
      commit("SET_LOAD_STATE", false);
      commit("SET_ADVERT_LOAD_STATE", false);
    },
  },
  plugins: [createPersistedState()],
});
