var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"z-[996] relative left-0 top-[1.5vh] max-lg:mt-24 bg-cvus-primary max-w-[240px] h-[97vh] max-lg:h-[70vh] overflow-y-auto shadow-cvus-primary-light/25 rounded-r-lg shadow-lg border-cvus-primary text-white border-r-2 py-5 max-lg:fixed flex flex-col gap-4",class:_vm.dataSidebar == true
      ? 'w-[22%] maxw max-xl:w-[22%] max-lg:w-[0%] max-md:w-[0%] transition-all ease-in-out'
      : 'w-[5%] max-xl:w-[7%] max-lg:w-[27%] max-md:w-full transition-all ease-in-out'},[_c('div',{staticClass:"mx-auto px-3"},[(_vm.dataSidebar)?_c('img',{staticClass:"mx-auto mt-3 w-full h-12 object-contain drop-shadow-lg",attrs:{"src":require("@/assets/global/cvusLogo.png"),"alt":""}}):_c('img',{staticClass:"mx-auto mt-3 w-12 h-12 drop-shadow-lg",attrs:{"src":require("@/assets/global/logo.svg"),"alt":""}})]),_c('div',{staticClass:"mt-10 max-lg:mt-8"},_vm._l((_vm.permittedMenuItems),function(item){return _c('div',{key:item.id},[_c('router-link',{staticClass:"w-full",class:_vm.dataSidebar == true ? 'max-lg:hidden' : 'max-lg:block ',attrs:{"to":item.disabled ? '' : item.to}},[_c('div',{staticClass:"flex w-[98%] items-center mt-2 border-l-2 text-slate-50 rounded-r-lg border-transparent px-5 max-lg:gap-5 hover:bg-white hover:bg-opacity-5 hover:!border-gray-200 hover:!text-white",class:item.routerName.includes(_vm.$route.name)
              ? _vm.active
              : item.disabled
              ? 'opacity-50'
              : '',on:{"click":function($event){_vm.dataSidebar = _vm.isMobile ? !_vm.dataSidebar : _vm.dataSidebar == true}}},[_c('div',{staticClass:"flex items-center justify-center max-lg:justify-start",class:_vm.dataSidebar == false ? 'lg:w-full mr-0 py-2 my-2 ' : 'mr-4'},[_c('img',{staticClass:"object-contain text-slate-50",class:{
                'h-5 w-5': _vm.dataSidebar == false,
                'h-6 w-6': _vm.dataSidebar,
                'opacity-100': _vm.$route.name == item.routerName,
                'opacity-60': _vm.$route.name != item.routerName,
                'opacity-20': item.disabled,
              },attrs:{"src":item.icon}})]),_c('button',{staticClass:"text-left flex justify-between py-3.5 w-full items-center font-semibold text-[0.9rem] font-Arial",class:{ 'hidden max-lg:inline': _vm.dataSidebar == false }},[_vm._v(" "+_vm._s(item.name)+" "),(item.disabled)?_c('span',{staticClass:"text-red-600 opacity-100 text-sm"},[_vm._v(" "+_vm._s(_vm.$t("globalComponents.sideBar.soon"))+" ")]):_vm._e()])])])],1)}),0),_c('div',{staticClass:"mt-auto flex flex-col gap-4 w-full"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex w-full items-center mt-2 border-l-2 gap-4 text-slate-50 border-white px-5"},[_c('button',{staticClass:"w-6 h-6 flex items-center justify-center mx-auto",on:{"click":function($event){return _vm.clearAll()}}},[_c('img',{attrs:{"src":require("@/assets/sidebar/signOut.svg"),"alt":""}})]),_c('button',{staticClass:"text-left py-4 w-full items-center font-semibold text-base",class:_vm.dataSidebar == false && 'hidden max-lg:block',on:{"click":function($event){return _vm.clearAll()}}},[_vm._v(" "+_vm._s(_vm.$t("globalComponents.sideBar.signOut"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }