<template>
  <modal
    name="addUser"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '60%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("user.components.add.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form @submit.prevent="save()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <div class="flex justify-between items-center gap-x-2">
          <customInput
            v-model="firstName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.add.table.name')"
          />

          <customInput
            v-model="lastName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.add.table.surname')"
          />
        </div>

        <customInput
          v-model="email"
          type="email"
          min="3"
          max="40"
          :required="true"
          :title="$t('user.components.add.table.email')"
        />

        <div v-if="hasCompanyAuth">
          <customSelect
            v-model="company"
            :title="$t('user.components.add.table.company')"
            :placeholder="$t('user.components.add.table.selectCompany')"
            :required="true"
            :loading="companiesLoading"
            :options="companyOptions"
            option-label="name"
            option-value="id"
            @input="handleCompanyChange"
            @load-more="loadMoreCompanies"
          />
        </div>

        <div>
          <customSelect
            v-model="roleId"
            :title="$t('user.components.add.table.role')"
            :placeholder="$t('user.components.add.table.selectRole')"
            :required="true"
            :loading="rolesLoading"
            :options="roleOptions"
            option-label="name"
            option-value="id"
            @input="handleRoleChange"
            :disabled="!hasRoleAuth"
          />
          <p v-if="!hasRoleAuth" class="text-red-500 text-sm mt-1">
            {{ $t("user.components.add.noRoleAuth") }}
          </p>
        </div>

        <requiredField />

        <buttonItem :load="load" buttonClass="w-full" :disabled="!canSubmitForm" />
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import customSelect from "@/components/customSelect.vue";
import { users } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "addUser",
  components: {
    customInput,
    customSelect,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      firstName: "",
      lastName: "",
      email: "",
      company: null,
      roleId: null,
      
      // Companies data
      companiesLoading: false,
      companyOptions: [],
      companyPage: 0,
      companySearch: "",
      companyTotalPages: 1,
      
      // Roles data
      rolesLoading: false,
      roleOptions: [],
      rolePage: 0,
      roleTotalPages: 1,
      
      load: false,
    };
  },
  computed: {
    hasCompanyAuth() {
      return this.canRender(16, 52);
    },
    hasRoleAuth() {
      return this.canRender(1, 48);
    },
    canSubmitForm() {
      // If no company auth, we use the store's company ID, so only role is needed
      if (!this.hasCompanyAuth) {
        return this.hasRoleAuth && this.roleId;
      }
      // Otherwise, need both company selection and role selection
      return (this.hasCompanyAuth && this.company) && (this.hasRoleAuth && this.roleId);
    },
    userCompanyId() {
      return this.$store.state.userData.companyId;
    }
  },
  watch: {
    company(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.hasRoleAuth) {
        this.rolesLoading = true;
        this.companiesLoading = true;
        this.getRoles();
        this.roleId = null;
      }
    },
  },

  methods: {
    show() {
      this.resetForm();
      this.$modal.show("addUser");
      // Load initial company and role options only if authorized
      if (this.hasCompanyAuth) {
        this.getCompanies();
      }
      if (this.hasRoleAuth) {
        this.getRoles();
      }
    },
    hide() {
      this.$modal.hide("addUser");
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.company = null;
      this.roleId = null;
    },
    
    // Company methods
    getCompanies() {
      if (!this.hasCompanyAuth) {
        return;
      }

      this.companiesLoading = true;
      this.axios
        .get(
          users.getCompaniesAll,
          {
            params: {
              page: this.companyPage,
              search: this.companySearch
            }
          }
        )
        .then((res) => {
          if (res.data && res.data.data) {
            const { detail, pageCount } = res.data.data;
            this.companyTotalPages = pageCount;
            
            // Append or replace options based on page
            if (this.companyPage === 0) {
              this.companyOptions = detail.map(company => ({
                id: company.id,
                name: company.name
              }));
            } else {
              this.companyOptions = [
                ...this.companyOptions,
                ...detail.map(company => ({
                  id: company.id,
                  name: company.name
                }))
              ];
            }
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.companiesLoading = false;
        });
    },
    handleCompanyChange(value) {
      this.company = value;
    },
    searchCompanies(query) {
      this.companySearch = query;
      this.companyPage = 0;
      this.getCompanies();
    },
    loadMoreCompanies() {
      if (this.companyPage < this.companyTotalPages - 1) {
        this.companyPage++;
        this.getCompanies();
      }
    },
    getRoles() {
      if (!this.hasRoleAuth) {
        return;
      }

      this.rolesLoading = true;

      const params = {};
      if (this.hasCompanyAuth && this.company) {
        params.company = this.company;
      } else if (!this.hasCompanyAuth && this.userCompanyId) {
        params.company = this.userCompanyId;
      }

      this.axios.get(users.getRolesAll, { params })
        .then((res) => {
          if (res.data && res.data.data) {
            this.roleOptions = res.data.data.map(role => ({
              id: role.roleId,
              name: role.name
            }));
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.rolesLoading = false;
          this.companiesLoading = false;
        });
    },
    handleRoleChange(value) {
      this.roleId = value;
    },

    
    validate() {
      if (!this.firstName || this.firstName.length < 3) {
        this.warningMesage(this.$t("user.components.add.table.nameError"));
        return false;
      }
      if (!this.lastName || this.lastName.length < 3) {
        this.warningMesage(this.$t("user.components.add.table.surnameError"));
        return false;
      }
      if (!this.email || !/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.warningMesage(this.$t("user.components.add.table.emailError"));
        return false;
      }
      
      // Only validate company if user has company auth
      if (this.hasCompanyAuth && !this.company) {
        this.warningMesage(this.$t("user.components.add.table.companyError"));
        return false;
      }
      
      // If user doesn't have company auth, make sure we have their company ID from store
      if (!this.hasCompanyAuth && !this.userCompanyId) {
        this.warningMesage(this.$t("user.components.add.companyRequired"));
        return false;
      }
      
      if (!this.roleId) {
        this.warningMesage(this.$t("user.components.add.table.roleError"));
        return false;
      }
      return true;
    },
    
    save() {
      if (!this.validate()) {
        return;
      }
      
      this.load = true;
      
      // Use either selected company or user's company from store
      const companyId = this.hasCompanyAuth ? this.company : this.userCompanyId;
      
      this.axios.post(users.add, {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            company: companyId,
            roleId: this.roleId
          }
        )
        .then((res) => {
          this.successMessage(res.data.message);
          this.resetForm();
          this.$emit("refresh", true);
          this.hide();
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
