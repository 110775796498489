<template>
  <div class="w-full pt-12">
    <navbar 
        :load="getLoad"
        :buttonLoad="load"
        :title="$t('settings.configration.index.title')"
        :description="$t('settings.configration.index.text')"
    />
    <div class="mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4">
        <customTabs 
            :tabs="tabs" 
            :activeTab="activeTab" 
            @tab-click="handleTabClick"
        />
        <div class="py-4">
            <keep-alive>
                <component :is="activeComponent"></component>
            </keep-alive>
        </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/navbar.vue";
import customTabs from '@/components/customTabs.vue';
import EmailSettings from './email/index.vue';
import Assessment from './assessment/index.vue';
import customLoading from "@/components/customLoading.vue";

export default {
  name: "configuration-page",
  components: {
    navbar,
    customTabs,
    EmailSettings,
    Assessment,
    customLoading,
  },
  data() {
    return {
      load: false,
      getLoad: false,
    };
  },
  computed: {
    tabs() {
      return [
        { id: 'email', name: this.$t('settings.configration.tabs.email') },
        { id: 'assessment', name: this.$t('settings.configration.tabs.assessment') }
      ];
    },
    activeTab() {
      const tabFromQuery = this.$route.query.tab;
      if (tabFromQuery && this.isValidTab(tabFromQuery)) {
        return tabFromQuery;
      }
      return 'email';
    },
    activeComponent() {
      switch (this.activeTab) {
        case 'email':
          return 'EmailSettings';
        case 'assessment':
          return 'Assessment';
        default:
          return 'EmailSettings';
      }
    }
  },
  methods: {
    isValidTab(tabId) {
      return this.tabs.some(tab => tab.id === tabId);
    },
    handleTabClick(tabId) {
      this.$router.replace({
        query: { ...this.$route.query, tab: tabId }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }
  }
};
</script>
