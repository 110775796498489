<template>
    <div class="flex justify-between max-sm:flex-col-reverse">
      <h4 class="text-lg tracking-wider textcol font-semibold">
        <span class="border-t-2 pt-1 border-[#344CA0]">{{ title }}</span>
      </h4>

      <div class="flex justify-end items-center gap-5 max-sm:justify-between max-sm:mb-6">
        <div class="relative group flex justify-center" v-if="canRender(14, 15)">
          <button  @click="add"  class="w-22 py-2 text-base rounded text-black">
            <i class="fas fa-plus mr-1"></i>
            {{ $t("cv.profile.information.components.topBar.add") }}
          </button>
          <tooltip
            :title="addText"
            tipClass=" bottom-8 text-center max-sm:ml-2"
          />
        </div>
      </div>
    </div>
</template>
<script>
import tooltip from "@/components/tooltip.vue";

export default {
    props:['title',"addText"],
    components:{
      tooltip
    },
    methods:{
        add(){
            this.$emit('add',true)
        }
    }
}
</script>