import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import profilePage from "../views/profile/index.vue";

import cvPage from "../views/cv/index.vue";
import cvProfilePage from "../views/cv/profile/index.vue";
import saveMiddlePage from "../views/cv/cvSaveMiddle.vue";
import cvSimilarPage from "../views/cvSimilar/index.vue";

import generalPage from "../views/general/index.vue";

import meetingPage from "../views/meeting/meetingConnect/index.vue";
import meetingLivePage from "../views/meeting/meetingLive/index.vue";

import projectPage from "../views/project/index.vue";
import projectUserPage from "../views/project/projectUser/index.vue";

import supportPage from "../views/support/index.vue";
import supportDetailPage from "../views/support/detail/index.vue";

import hiredPage from "../views/hired/index.vue";

//import companyPage from '../views/company/index.vue';

import NotFoundPage from "../views/error/index.vue";
import usersPage from "../views/users/index.vue";

//settings
import settingPage from "../views/settings/index.vue";
import settingGeneralPage from "../views/settings/general/index.vue";
import contactPage from "../views/settings/contact/index.vue";
import settingConfigrationPage from "../views/settings/configration/index.vue";
import personalInformationSettings from "../views/settings/position/personalInformation/index.vue";
import inadequateSettings from "../views/settings/position/inadequate/index.vue";
import referenceSettings from "../views/settings/position/reference/index.vue";
import positionSettings from "../views/settings/position/index.vue";
// auth
import signInPage from "../views/auth/signIn.vue";
import signUpPage from "../views/auth/signUp.vue";
import setPasswordPage from "../views/auth/setPassword.vue";
import confirmPasswordPage from "../views/auth/confirmPassword.vue";

// outlook
import outlookPage from "../views/oauth/outlookConnection/index";

//meet connection
import meetPage from "../views/oauth/meetConnection/index.vue";

//privacy policy
import privacyPolicy from "../views/privacy-policy/index.vue";

/**
 * Create route meta data with permission check
 */
const withPermission = (moduleId, actionId, redirectPath = '/', additionalMeta = {}) => {
  const fallbackRoutes = [
    { path: '/project', moduleId: 3, actionId: 1 }, // View Positions
    { path: '/cv', moduleId: 2, actionId: 6 }, // View CV
    { path: '/general', moduleId: 15, actionId: 45 }, // Mark Candidate as Ineligible
    { path: '/hired', moduleId: 7, actionId: 17 }, // View Hired Candidates
    { path: '/users', moduleId: 1, actionId: 19 }, // View Users
    { path: '/setting', moduleId: 6, actionId: 23 } // Edit/Delete General Settings
  ];
  
  return {
    requiresAuth: true,
    moduleId,
    actionId,
    redirectNoPermission: redirectPath,
    fallbackRoutes,
    ...additionalMeta
  };
};

/**
 * Create a public route meta (no auth required)
 */
const publicRoute = (additionalMeta = {}) => {
  return {
    requiresAuth: false,
    ...additionalMeta
  };
};

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "signIn",
    component: signInPage,
    meta: publicRoute(),
  },
  {
    path: "/sign/up",
    name: "signUp",
    component: signUpPage,
    meta: publicRoute(),
  },
  {
    path: "/set/password",
    name: "setPassword",
    component: setPasswordPage,
    meta: publicRoute(),
  },
  {
    path: "/confirm/password",
    name: "confirmPassword",
    component: confirmPasswordPage,
    meta: publicRoute(),
  },
  {
    path: "/profile",
    name: "profile",
    component: profilePage,
    meta: withPermission(null, null, "/", { user: true }),
  },
  {
    path: "/cv",
    name: "cv",
    component: cvPage,
    meta: withPermission(2, 6, "/project"),
  },
  {
    path: "/cv/profile/:id",
    name: "cvProfile",
    component: cvProfilePage,
    meta: withPermission(14, 11, "/cv"),
  },
  {
    path: "/cv/save/middleware",
    name: "cvSaveMiddleware",
    component: saveMiddlePage,
    meta: withPermission(2, 7, "/cv"),
  },
  {
    path: "/cv/similar",
    name: "cvSimilarPage",
    component: cvSimilarPage,
    meta: withPermission(null, null, "/", { user: true }),
  },
  {
    path: "/general",
    name: "general",
    component: generalPage,
    meta: withPermission(8, 16, "/", { user: true }),
  },
  {
    path: "/meeting",
    name: "meeting",
    component: meetingPage,
    meta: withPermission(null, null),
  },
  {
    path: "/meeting/live",
    name: "meetingLive",
    component: meetingLivePage,
    meta: withPermission(null, null),
  },
  {
    path: "/project",
    name: "project",
    component: projectPage,
    meta: withPermission(3, 1, "/cv"),
  },
  {
    path: "/project/user",
    name: "projectUser",
    component: projectUserPage,
    meta: withPermission(3, 1, "/project"),
  },
  {
    path: "/users",
    name: "users",
    component: usersPage,
    meta: withPermission(1, 19, "/"),
  },
  {
    path: "/hired",
    name: "hired",
    component: hiredPage,
    meta: withPermission(7, 17, "/project"),
  },
  {
    path: "/support",
    name: "support",
    component: supportPage,
    meta: withPermission(null, null, "/", { user: true }),
  },
  {
    path: "/support/:id",
    name: "supportDetail",
    component: supportDetailPage,
    meta: withPermission(null, null, "/", { user: true }),
  },
  //settings
  {
    path: "/setting",
    name: "setting",
    component: settingPage,
    meta: withPermission(null, null, "/"),
    children: [

      {
        path: "/setting/position",
        name: "settingPosition",
        component: positionSettings,
        meta: withPermission(null, null, "/setting"),
      },
      {
        path: "/setting/contact",
        name: "settingContact",
        component: contactPage,
        meta: withPermission(6, 24, "/setting"),
      },
      {
        path: "/setting/configration",
        name: "settingConfigration",
        component: settingConfigrationPage,        meta: withPermission(6, 25, "/setting"),
      },
      {
        path: "/setting/general",
        name: "settingGeneral",
        component: settingGeneralPage,
        meta: withPermission(6, 23, "/setting"),
      },

    ]
  },
  // {
  //   path: "/setting/contact",
  //   name: "contact",
  //   component: contactPage,
  //   meta: withPermission(6, 24, "/setting"),
  // },
  // {
  //   path: "/setting/configration",
  //   name: "configration",
  //   component: settingConfigrationPage,
  //   meta: withPermission(6, 25, "/setting"),
  // },
  // {
  //   path: "/setting/position",
  //   name: "position",
  //   component: positionSettings,
  //   meta: withPermission(null, null, "/setting"),
  // },
  
  {
    path: "/outlook",
    name: "outlook",
    component: outlookPage,
    meta: withPermission(null, null),
  },
  {
    path: "/meet/connection",
    name: "meetConnection",
    component: meetPage,
    meta: withPermission(null, null),
  },

  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
    meta: publicRoute(),
  },
  
  // {
  //   path: '/company',
  //   name: 'company',
  //   component: companyPage,
  //   meta: { requiresAuth: true }
  // },
  {
    path: "*",
    name: "notFound",
    component: NotFoundPage,
    meta: withPermission(null, null, "/", { user: true }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.userData.isAuth;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  // Check if route requires authentication and user is not authenticated
  if (requiresAuth && !isAuthenticated) {
    return next("/");
  }
  
  // Check for module action permission if specified in the route meta
  if (to.meta.moduleId && to.meta.actionId) {
    if (store.getters.hasPermission(to.meta.moduleId, to.meta.actionId)) {
      return next(); // User has permission for this route
    } else {
      if(to.meta.redirectNoPermission) {
        const redirectPath = to.meta.redirectNoPermission;
        // Resolve the redirect route to get its meta information
        const redirectRoute = router.resolve(redirectPath);
        console.log(redirectRoute, 'redirectRoute');
        // Check if redirect route requires permissions
        if (redirectRoute?.resolved?.meta.moduleId !== null && redirectRoute?.resolved?.meta.actionId !== null) {
          if(store.getters.hasPermission(redirectRoute?.resolved?.meta.moduleId, redirectRoute?.resolved?.meta.actionId)) {
            return next(redirectPath);
          }
        } else {
          // If redirect route doesn't require permissions, allow redirect
          return next(redirectPath);
        }

        // If we get here, user doesn't have permission for redirect route
        if(to.meta.fallbackRoutes) {
          //check which fallback route has permission
          const fallbackRoute = to.meta.fallbackRoutes.find(route => 
            store.getters.hasPermission(route.moduleId, route.actionId)
          );
          if(fallbackRoute) {
            return next(fallbackRoute.path);
          }
        }
        
        // If no fallback route is available, sign out and redirect to home
        store.commit("signOut");
        return next("/");
      }
      return next("/");
    }
  }
  
  // For routes without specific module/action restrictions but with user flag
  if (to.matched.some(record => record.meta.user)) {
    return next();
  }
  
  // For routes without specific restrictions
  if (!to.meta.moduleId && !to.meta.adminOnly) {
    return next();
  }
  
  // Default fallback - deny access
  return next("/");
});

export default router;
