<template>
    <div >
        <router-view />
    </div>
</template>

<script>    
export default {
    name: 'SettingsPage',
   methods:{
    goToAvailableRoute(){
        if(this.canRender(6, 23)){
            this.$router.push('/setting/general')
        }else if(this.canRender(6, 24)){
            this.$router.push('/setting/contact')
        }else if(this.canRender(6, 25)){
            this.$router.push('/setting/configration')
        }else if(this.canRender(6, 26)){
            this.$router.push('/setting/position')
        }else if(this.canRender(6, 27)){
            this.$router.push('/setting/position')

        }else if(this.canRender(6, 28)){
            this.$router.push('/setting/position')

        }else{
            this.$router.push('/')
        }
    }
   },
   mounted(){
    this.goToAvailableRoute()
   },

   watch: {
    $route(to, from){
        if(to.name == 'setting'){
            this.goToAvailableRoute()
        }
    }
   }

 
}
</script>
