<template>
  <div class="custom-tabs" :class="variant">
    <div class="tabs-container relative">
      <!-- Left scroll button -->
      <button 
        v-show="canScrollLeft" 
        class="scroll-button left-0 z-10 absolute top-1/2 transform -translate-y-1/2 p-1 text-gray-500 bg-white rounded-full shadow-md hover:bg-gray-50"
        @click="scrollLeft"
        aria-label="Scroll left"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </button>

      <!-- Tabs container with overflow hidden -->
      <div 
        ref="tabsWrapper"
        class="overflow-hidden"
      >
        <div 
          ref="tabsScroller"
          class="tabs-scroller flex space-x-8 whitespace-nowrap transition-transform duration-300"
          @scroll="handleScroll"
        >
          <button 
            v-for="tab in tabs" 
            :key="tab.id"
            @click="$emit('tab-click', tab.id)"
            class="py-4 px-1 font-medium text-sm whitespace-nowrap transition-colors"
            :class="activeTab === tab.id ? activeClass : inactiveClass"
            :style="{ minWidth: `${minWidth}px`, width: 'auto' }"
          >
            <span v-if="tab.icon" class="mr-2 inline-flex align-middle text-lg">
              <Icon :icon="tab.icon" />
            </span>
            {{ tab.name }}
          </button>
        </div>
        
        <!-- Absolute sliding border indicator -->
        <div 
          ref="activeBorder" 
          class="active-tab-indicator absolute bottom-0 h-0.5 flex bg-cvus-primary transition-all duration-300"
        ></div>
      </div>

      <!-- Right scroll button -->
      <button 
        v-show="canScrollRight" 
        class="scroll-button right-0 z-10 absolute top-1/2 transform -translate-y-1/2 p-1 text-gray-500 bg-white rounded-full shadow-md hover:bg-gray-50"
        @click="scrollRight"
        aria-label="Scroll right"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomTabs',
  components: {
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    activeClass: {
      type: String,
      default: 'text-primary-600'
    },
    inactiveClass: {
      type: String,
      default: 'text-gray-500 hover:text-gray-700'
    },
    minWidth: {
      type: Number,
      default: 120
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'pills', 'minimal', 'underline'].includes(value)
    }
  },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
      observer: null
    }
  },
  mounted() {
    this.checkScrollButtons()
    window.addEventListener('resize', this.checkScrollButtons)
    
    // Use MutationObserver to detect DOM changes
    this.observer = new MutationObserver(this.checkScrollButtons)
    this.observer.observe(this.$refs.tabsScroller, { 
      childList: true, 
      subtree: true,
      attributes: true
    })
    
    // Check if active tab is visible, if not scroll to it
    this.$nextTick(() => {
      this.scrollToActiveTab()
      this.updateActiveBorderPosition()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.checkScrollButtons()
      this.updateActiveBorderPosition()
    })
  },
  watch: {
    activeTab() {
      this.$nextTick(() => {
        this.updateActiveBorderPosition()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScrollButtons)
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    getVisibleTabWidth() {
      const container = this.$refs.tabsScroller
      if (!container || !container.children || container.children.length === 0) {
        return 100 // Default fallback
      }
      
      // Calculate average button width
      let totalWidth = 0
      for (let i = 0; i < container.children.length; i++) {
        totalWidth += container.children[i].offsetWidth
      }
      
      const avgWidth = totalWidth / container.children.length
      // Return either average width or container width, whichever is smaller
      return Math.min(avgWidth * 1.5, container.clientWidth * 0.8)
    },
    
    scrollLeft() {
      const container = this.$refs.tabsScroller
      if (container) {
        const scrollAmount = this.getVisibleTabWidth()
        container.scrollBy({
          left: -scrollAmount,
          behavior: 'smooth'
        })
        
        // Force check after animation completes
        setTimeout(() => this.checkScrollButtons(), 300)
      }
    },
    
    scrollRight() {
      const container = this.$refs.tabsScroller
      if (container) {
        const scrollAmount = this.getVisibleTabWidth()
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth'
        })
        
        // Force check after animation completes
        setTimeout(() => this.checkScrollButtons(), 300)
      }
    },
    
    handleScroll() {
      this.checkScrollButtons()
    },
    
    checkScrollButtons() {
      const container = this.$refs.tabsScroller
      if (container) {
        // Use a small threshold to account for rounding errors
        const threshold = 1
        this.canScrollLeft = container.scrollLeft > threshold
        this.canScrollRight = Math.ceil(container.scrollLeft) < (container.scrollWidth - container.clientWidth - threshold)
      }
    },
    
    scrollToActiveTab() {
      const activeButton = this.$el.querySelector(`.${this.activeClass.split(' ')[0]}`)
      if (activeButton && this.$refs.tabsScroller) {
        const container = this.$refs.tabsScroller
        const containerRect = container.getBoundingClientRect()
        const buttonRect = activeButton.getBoundingClientRect()
        
        // If active tab is outside the viewport, scroll to it
        if (buttonRect.left < containerRect.left || buttonRect.right > containerRect.right) {
          activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
          
          // Force check after animation completes
          setTimeout(() => this.checkScrollButtons(), 300)
        }
      }
    },
    
    updateActiveBorderPosition() {
      const activeButton = this.$el.querySelector(`.${this.activeClass.split(' ')[0]}`)
      const borderIndicator = this.$refs.activeBorder
      
      if (activeButton && borderIndicator && this.$refs.tabsScroller) {
        const container = this.$refs.tabsScroller
        const buttonRect = activeButton.getBoundingClientRect()
        const containerRect = container.getBoundingClientRect()
        
        // Calculate the left position relative to the container
        const leftPosition = buttonRect.left - containerRect.left + container.scrollLeft
        
        // Set the position and width of the border indicator
        borderIndicator.style.left = `${leftPosition}px`
        borderIndicator.style.width = `${buttonRect.width}px`
      }
    }
  }
}
</script>

<style scoped>
.tabs-scroller {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  border-bottom: 1px solid #e5e7eb; /* Default border */
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabs-scroller::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-tab-indicator {
  height: 2px;
  bottom: 0;
  z-index: 1;
}

/* Variant styles */
.custom-tabs.pills .tabs-scroller {
  border-bottom: none;
}

.custom-tabs.pills button {
  border-radius: 9999px;
  padding-left: 16px;
  padding-right: 16px;
}

.custom-tabs.pills .active-tab-indicator {
  display: none;
}

.custom-tabs.minimal .tabs-scroller {
  border-bottom-color: transparent;
}

.custom-tabs.underline .active-tab-indicator {
  height: 3px;
}
</style> 