<template>
  <modal
    name="edit-role"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '65%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t('role.components.edit.title') }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="save()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <customInput
          v-model="name"
          type="text"
          min="3"
          max="40"
          :required="true"
          :title="$t('role.components.edit.table.name')"
        />
        
        <div class="space-y-2">
          <label class="block font-medium">{{ $t('role.components.edit.table.status') }}</label>
          <div class="flex items-center">
            <customCheckbox 
              v-model="status"
              :label="status ? $t('role.components.edit.table.active') : $t('role.components.edit.table.inactive')"
            />
          </div>
        </div>
        
        <div class="space-y-2">
          <customCard 
            :title="$t('role.components.edit.table.permissions')" 
            variant="primary"
            size="sm"
            :loading="modulesLoading"
          >
            <div class="grid grid-cols-2 gap-4">
              <div v-for="(module, index) in modules" :key="index">
                <customCard 
                  :isAccordion="true" 
                  :defaultOpen="false"
                  variant="default"
                  size="sm"
                >
                  <template #title>
                    <div class="flex items-center gap-2  w-full">
                      <div class="flex items-center gap-2" @click.stop>
                        <customCheckbox 
                       
                          v-model="module.selectAll" 
                          @input="toggleSelectAll(module, $event)"
                        />
                        </div>
                        <span>{{ module.name }} <span class="text-xs text-gray-500">({{ getSelectedCount(module) }}/{{ module.actions.length }})</span></span>
                    </div>
                  </template>
                  <div class="flex flex-wrap gap-2 gap-y-4 h-36 overflow-y-auto justify-start items-start content-start">
                    <div v-for="(action, actionIndex) in module.actions" :key="actionIndex" class="flex w-full max-w-[calc(50%-8px)] h-fit" >
                      <customCheckbox 
                        v-model="action.selected"
                        :label="action.name"
                      />
                    </div>
                  </div>
                </customCard>
              </div>
            </div>
          </customCard>
        </div>

        <requiredField />

        <buttonItem :load="load" buttonClass="w-full" />
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import customCard from "@/components/customCard.vue";
import customCheckbox from "@/components/customCheckbox.vue";
import { users } from "@/networking/urlmanager";

export default {
  name: "editRole",
  props: ["detail"],
  components: {
    customInput,
    buttonItem,
    requiredField,
    customCard,
    customCheckbox,
  },
  data() {
    return {
      isModalOver: false,
      name: "",
      status: true,
      modules: [],
      modulesLoading: false,
      roleId: null,
      load: false,
    };
  },
 
  watch: {
    detail: {
      handler(val) {
        if (val && val.id) {
          this.roleId = val.id;
          this.name = val.name;
          this.status = val.status;
          
          // Get modules first if not loaded yet
          if (this.modules.length === 0) {
            this.getModules(val.moduleList);
          } else {
            // Apply selected actions from detail
            this.applySelectedActions(val.moduleList);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    show() {
      this.$modal.show("edit-role");
      if (this.modules.length === 0) {
        this.getModules();
      }
    },
    hide() {
      this.$modal.hide("edit-role");
    },
    getModules(roleModuleList = null) {
      this.modulesLoading = true;
      this.modules = [];
      this.axios
        .get(users.getModulesAll)
        .then((res) => {
          if (res.data && res.data.data) {
            this.modules = res.data.data.map(module => ({
              ...module,
              selectAll: false,
              actions: module.actions.map(action => ({
                ...action,
                selected: false
              }))
            }));
            
            // Apply selected actions if we have role data
            if (roleModuleList) {
              this.applySelectedActions(roleModuleList);
            }
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.modulesLoading = false;
        });
    },
    applySelectedActions(roleModuleList) {
      if (!roleModuleList || !Array.isArray(roleModuleList)) return;
      
      // Reset all selections first
      this.modules.forEach(module => {
        module.actions.forEach(action => {
          action.selected = false;
        });
        module.selectAll = false;
      });
      
      // Apply selections from role data
      roleModuleList.forEach(roleModule => {
        const module = this.modules.find(m => m.id === roleModule.moduleId);
        if (module) {
          // Mark actions as selected
          if (roleModule.actions && Array.isArray(roleModule.actions)) {
            roleModule.actions.forEach(roleAction => {
              const action = module.actions.find(a => a.id === roleAction.id);
              if (action) {
                action.selected = true;
              }
            });
            
            // Update selectAll status
            module.selectAll = this.isAllSelected(module);
          }
        }
      });
    },
    validate() {
      if (this.name.length < 3) {
        this.warningMesage(this.$t('role.components.edit.table.nameError'));
        return false;
      }
      
      // Check if at least one action is selected
      const hasActions = this.modules.some(module => 
        module.actions.some(action => action.selected)
      );
      
      if (!hasActions) {
        this.warningMesage(this.$t('role.components.edit.table.permissionsError'));
        return false;
      }
      return true;
    },
    save() {
      if (!this.validate()) {
        return;
      }
      
      this.load = true;
      
      // Prepare module list for API
      const moduleList = [];
      
      // Build moduleList from selected actions
      this.modules.forEach(module => {
        const selectedActions = module.actions
          .filter(action => action.selected)
          .map(action => ({
            id: action.id
          }));
          
        if (selectedActions.length > 0) {
          moduleList.push({
            moduleId: module.id,
            actions: selectedActions
          });
        }
      });
      
      this.axios
        .put(users.editRole, {
          roleId: this.roleId,
          name: this.name,
          status: this.status ? 1 : 0,
          moduleList: moduleList
        })
        .then((res) => {
          this.successMessage(res.data.message || this.$t('role.components.edit.table.success'));
          this.hide();
          this.$emit("refresh");
        })
        .catch((err) => {
          const errorMessage = err.response
            ? err.response.data.message
            : err.message;
          this.warningMesage(errorMessage);
        })
        .finally(() => {
          this.load = false;
        });
    },
    isAllSelected(module) {
      const allSelected = module.actions.every(action => action.selected);
      module.selectAll = allSelected;
      return allSelected;
    },
    toggleSelectAll(module, value) {
      module.actions.forEach(action => {
        action.selected = value;
      });
      module.selectAll = value;
    },
    getSelectedCount(module) {
      return module.actions.filter(action => action.selected).length;
    },
  },
};
</script> 