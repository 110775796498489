<template>
  <modal
    name="add-company"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '400px'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t('company.components.add.title') }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="save()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <customInput
          v-model="name"
          type="text"
          min="3"
          max="40"
          :required="true"
          :title="$t('company.components.add.table.name')"
        />

        <requiredField />

        <buttonItem :load="load" buttonClass="w-full" />
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import { users } from "@/networking/urlmanager";

export default {
  name: "addCompany",
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      name: "",
      load: false,
    };
  },

  methods: {
    show() {
      this.$modal.show("add-company");
    },
    hide() {
      this.$modal.hide("add-company");
      this.resetForm();
    },
    resetForm() {
      this.name = "";
    },
    validate() {
      if (this.name.length < 3) {
        this.warningMesage(this.$t('company.components.add.table.nameError'));
        return false;
      }
      return true;
    },
    save() {
      if (!this.validate()) {
        return;
      }
      
      this.load = true;
      
      this.axios
        .post(
          users.addCompany,
          {
            name: this.name
          }
        )
        .then((res) => {
          this.successMessage(res.data.message || this.$t('company.components.add.table.success'));
          this.hide();
          this.$emit("refresh");
        })
        .catch((err) => {
          const errorMessage = err.response
            ? err.response.data.message
            : err.message;
          this.warningMesage(errorMessage);
        })
        .finally(() => {
          this.load = false;
        });
    }
  },
};
</script> 