<template>
  <div class="flex gap-x-3 gap-y-1 items-center mb-3 w-full mt-5">
    <div class="w-auto">
      <div
        class="w-16 h-16 rounded-full flex justify-center items-center"
        style="background: linear-gradient(180deg, #15b9b9 0%, #3c1dff 100%)"
      >
        <img class="w-6 h-6 object-contain" :src="img" alt="" />
      </div>
    </div>
    <div class="pr-2 text-left flex justify-start w-full max-sm:block">
      <div class="w-10/12 max-sm:w-full">
        <h1 class="text-base textcol font-light" v-if="title">{{ title }}</h1>
        <h3 class="text-gray-800 text-sm my-1" v-if="sub">{{ sub }}</h3>
        <h3 class="text-gray-800 text-xs" v-if="third">{{ third }}</h3>
        <h3 class="text-gray-800 text-xs mt-1" v-if="four">{{ four }}</h3>
        <h3 class="text-gray-800 text-xs mt-1" v-if="five">{{ five }}</h3>
      </div>

      <div
        class="w-2/12 max-sm:w-full max-sm:justify-start max-sm:mt-7 flex gap-5 items-center justify-end"
      >
        <button
          v-if="!isEditItem && canRender(14, 13)"
          @click="editItem"
          class="text-sm text-black text-opacity-40 hover:text-opacity-100"
        >
          <div class="relative group flex justify-center">
            <i class="fas fa-pen"></i>
            <tooltip
              :title="$t('cv.profile.information.components.card.ToolTipEdit')"
              tipClass=" bottom-6 w-[190px]  md:!-right-[50px]"
              rotateClass="md:ml-[75px] "
            />
          </div>
        </button>
        <button
          v-if="canRender(14, 14)"
          @click="deleteItem"
          class="text-sm text-black text-opacity-40 hover:text-opacity-100"
        >
          <div class="relative group flex justify-center">
            <i class="fas fa-trash"></i>
            <tooltip
              :title="
                $t('cv.profile.information.components.card.ToolTipDelete')
              "
              tipClass=" bottom-6 w-[100px] md:!-right-[25px] !max-sm:left-[0px]"
              rotateClass=" md:ml-[50px]"
            />
          </div>
        </button>
        <button
          v-if="isDraggable"
          class="text-black text-opacity-40 hover:text-opacity-100"
        >
          <div class="relative group flex justify-center">
            <i class="fas fa-bars"></i>
            <tooltip
              :title="
                $t('cv.profile.information.components.card.ToolTipChanging')
              "
              tipClass=" bottom-6 md:!right-[-20px] w-[160px]"
              rotateClass="md:ml-[105px]"
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import tooltip from "@/components/tooltip.vue";
export default {
  name: "car-comp",
  props: [
    "img",
    "rowId",
    "title",
    "sub",
    "third",
    "five",
    "four",
    "doesItContinue",
    "edit",
    "isDraggable",
    "isEditItem",
  ],
  components: {
    tooltip,
  },
  methods: {
    editItem() {
      this.$emit("editItem", true);
    },
    deleteItem() {
      this.$emit("deleteItem", true);
    },
  },
};
</script>
