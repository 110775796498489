import axios from "axios";
import store from "../store";
import router from "../router";
import i18n from "../plugins/i18n";
import { auth } from "../networking/urlmanager";

const instance = axios.create();
let isFetchingPermissions = false;
let permissionPromise = null;

instance.interceptors.request.use((config) => {
    const token = store.state.userData.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    const lang = store.state.userData.locale;
    if (lang) {
        config.headers['accept-language'] = lang;
    }

    const companyId = store.state.activeCompany?.id;
    if (companyId) {
        config.headers['company'] = companyId;
    }

    config.headers['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
},
    (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use((response) => response, async (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            store.commit("signOut");
            await router.push("/");
        } else if (error.response.status === 403) {
            try {
                if (!isFetchingPermissions) {
                    isFetchingPermissions = true;
                    permissionPromise = axios.get(auth.getPermission, {
                        headers: {
                            'Authorization': `Bearer ${store.state.userData.token}`
                        }
                    }).then(response => {
                        store.commit("setRole", response.data.data);
                        return;
                    }).finally(() => {
                        isFetchingPermissions = false;
                        permissionPromise = null;
                    });
                }

                await permissionPromise;
            } catch (innerError) {
                console.error("Error handling 403 response:", innerError);
            }
        }
        return Promise.reject(error);
    } else if (error.request) {
        return Promise.reject(i18n.t("error.index.network"));
    } else {
        return Promise.reject(i18n.t("error.index.unknown", error.message));
    }
}
);

export default instance;