<template>
  <div class="mt-5 h-full">
 
    <assessmentHistoryModal ref="assessmentHistoryModal" :candidateInfo="selectedItem"
    :projectId="$route.query.id"  customName="shortlistHistoryModal"
    />
    
    <CustomTable
      :items="List"
      :columns="columns"
      :loading="load"
      :currentPage="currentPage"
      :pageCount="pageCount"
      :totalCount="totalCount"
      @row-click="goToProfile"
      @page-change="currentPage = $event"
    >
    <template #header>
      <div class="flex gap-5 flex-wrap w-full justify-end">
        <slot name="header"></slot>
      </div>
    </template>
    <!-- Custom slots for each column -->
      <template #fullname="{ item }">
        <div class="flex gap-2 items-center cursor-pointer hover:underline">
         <customTextWrapper :text="item.fullname" />
        </div>
      </template>
      
      <template #interviewPoint="{ item }">
        <div 
          class="hover:text-slate-500 transition-all duration-200 cursor-pointer"
          @click="currentStateId ? '' : changePoint(item, 1)"
        >
          {{ item.interviewPoint || 0 }}
        </div>
      </template>
      
      <template #ikPoint="{ item }">
        <div 
          class="hover:text-slate-500 transition-all duration-200 cursor-pointer"
          @click="currentStateId ? '' : changePoint(item, 2)"
        >
          {{ item.ikPoint || 0 }}
        </div>
      </template>
      
      <template #technicalPoint="{ item }">
        <div 
          class="hover:text-slate-500 transition-all duration-200 cursor-pointer"
          @click="currentStateId ? '' : changePoint(item, 3)"
        >
          {{ item.technicalPoint || 0 }}
        </div>
      </template>
      
      <template #status="{ item }">
          <div
            class="text-xs flex items-center"
            :class="stateName(item.stateId).class"
          >
            {{ stateName(item.stateId).name }}
          </div>

      </template>
      <template #actions="{ item }">

        <div
            class="flex items-center justify-end gap-4"
            v-if="item.stateId && item.stateId != 6"
          >
            <!-- Calendar button -->
            <asyncButton
              v-if="
                item.stateId == 1 ||
                item.stateId == 2 ||
                item.stateId == 4 ||
                item.stateId == 3 ||
                canRender(12, 39)
              "
              :disabled="item.stateId == 7 || item.stateId == 8 || currentStateId"
              type="button"
              @click="$emit('showDateModal', item)"
              :hiddenText="true"
              :buttonClass="
                'w-full !px-0 !gap-0 ' +
                (item.stateId == 7 || item.stateId == 8 || currentStateId && '!bg-transparent')
              "
              :awesome="
                'fa-solid fa-calendar text-sm ' +
                (item.stateId == 7 || item.stateId == 8 || currentStateId && '!text-gray-600')
              "
              containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
              imgClass="w-5"
              container-class="w-full md:w-auto"
              :isTooltip="true"
              :toolTipTitle="
                $t('project.tableInterview.tooltip.createTechnical')
              "
              :tooltipTop="true"
              toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
              :spinnerClass="
                '!border-t-transparent !border-black !text-black' +
                (item.stateId == 5 && 'text-black text-opacity-30')
              "
              tooltipRotateClass="ml-[85px]"
            />
            
            <!-- Assessment button -->
            <asyncButton
              v-if="
                item.stateId == 1 ||
                item.stateId == 2 ||
                item.stateId == 4 ||
                item.stateId == 3 ||
                item.stateId == 5 || 
                canRender(12, 41)
              "
              :disabled="item.stateId == 7 || item.stateId == 8 || currentStateId"
              type="button"
              @click="showAssessmentModal(item)"
              :hiddenText="true"
              :buttonClass="
                'w-full !px-0 !gap-0 ' +
                (item.stateId == 7 || item.stateId == 8 || currentStateId && '!bg-transparent')
              "
              :awesome="
                'fa-solid fa-file text-sm ' +
                (item.stateId == 7 || item.stateId == 8 || currentStateId && '!text-gray-600')
              "
              containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
              imgClass="w-5"
              container-class="w-full md:w-auto"
              :isTooltip="true"
              :toolTipTitle="
                $t('project.tableInterview.tooltip.sendAssessment')
              "
              :tooltipTop="true"
              toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
              :spinnerClass="
                '!border-t-transparent !border-black !text-black' +
                (item.stateId == 5 && 'text-black text-opacity-30')
              "
              tooltipRotateClass="ml-[85px]"
            /> 
            
            <div class="flex gap-4 items-center" v-if="item.stateId != 8">
              <!-- Return button -->
              <asyncButton
                v-if="item.stateId == 7 && canRender(15, 45)"
                type="button"
                @click="handleContinueWithInadequate(item)"
                :hiddenText="true"
                :buttonClass="
                  'w-full !px-0 !gap-0 ' +
                  (currentStateId && '!bg-transparent')
                "
                :awesome="
                  'fa-solid fa-rotate-left font-bold text-green-700  '
                "
                containerBackground="!bg-transparent !text-black md:!w-auto justify-center !w-full"
                imgClass="w-5"
                container-class="w-full md:w-auto"
                :isTooltip="true"
                :toolTipTitle="
                  $t('project.tableInterview.tooltip.confirm')
                "
                :tooltipTop="true"
                toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                spinnerClass="!border-t-transparent !border-black !text-black"
                tooltipRotateClass="ml-4"
              />
              
              <!-- Reject button -->
              <asyncButton
                v-if="item.stateId != 7 && canRender(15, 45)"
                type="button"
                @click="deleteItem(item)"
                :hiddenText="true"
                :buttonClass="
                  'w-full !px-0 !gap-0 '
                "
                :awesome="
                  'fa-solid fa-xmark text-base text-red-600' 
                "
                containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
                imgClass="w-5"
                container-class="w-full md:w-auto"
                :isTooltip="true"
                :toolTipTitle="
                  $t('project.tableInterview.tooltip.reject')
                "
                :tooltipTop="true"
                toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                spinnerClass="!border-t-transparent !border-black !text-black"
                tooltipRotateClass="ml-4"
              />
              
              <!-- Add to reference list button -->
              <asyncButton
                v-if="isInterview(item) && item.stateId != 7 && item.stateId != 9 && canRender(13, 42)"
                type="button"
                @click="addReferenceList(item)"
                :hiddenText="true"
                :buttonClass="
                  'w-full !px-0 !gap-0 '
                "
                :awesome="
                  'fa-solid fa-arrow-right font-bold  ' +
                  (currentStateId && '!text-gray-600')
                "
                containerBackground="!bg-transparent !text-black md:!w-auto justify-center !w-full"
                imgClass="w-5"
                container-class="w-full md:w-auto"
                :isTooltip="true"
                :toolTipTitle="
                  $t('project.tableInterview.tooltip.addReferenceList')
                "
                :tooltipTop="true"
                toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                spinnerClass="!border-t-transparent !border-black !text-black"
                tooltipRotateClass="ml-4"
              >
              </asyncButton>
            </div>
          </div>
      </template>
      
      <!-- Pagination slot -->
      <template #pagination>
        <pagination
          v-model="currentPage"
          :pageCount="pageCount"
          :totalRowCount="totalCount"
        />
      </template>
    </CustomTable>
    
    <RejectModal
      ref="rejectModalRef"
      :item="selectedItem"
      :customName="'shortlist-reject'"
      @confirm="handleReject"
      @close="selectedItem = null"
    />
  </div>
</template>

<script>
// networking
import { shortList, personelInformation,referenceAndPayment,projectConnect } from "@/networking/urlmanager";

// global components
import pagination from "@/components/pagination.vue";
import asyncButton from "@/components/button.vue";
import assessmentHistoryModal from '../assessment/assessmentHistoryModal.vue';
import CustomTable from "@/components/customTable.vue";

import moment from "moment";
import "moment/locale/tr";
import RejectModal from './rejectModal.vue';
import customTextWrapper from "@/components/customTextWrapper.vue";
export default {
  name: "tableAsistan",
  components: {
    pagination,
    asyncButton,
    RejectModal,
    assessmentHistoryModal,
    CustomTable,
    customTextWrapper
  },
  data() {
    return {
      load: false,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      selectedItem: null,
      showModal: false,

      isDelete: 2,
      List: [],

      infoFormLoad: false,
      selectedIndex: null,
      columns: [
        {
          key: "fullname",
          label: this.$t("project.tableInterview.tableHead.candidate"),
          width: "200px",
          clickable: true
        },
        {
          key: "interviewPoint",
          label: this.$t("project.tableInterview.tableHead.preliminaryInterview"),
          width: "150px"
        },
        {
          key: "ikPoint",
          label: this.$t("project.tableInterview.tableHead.hrInterview"),
          width: "150px"
        },
        {
          key: "technicalPoint",
          label: this.$t("project.tableInterview.tableHead.technicalInterview"),
          width: "150px"
        },
        {
          key: "status",
          label: this.$t("project.tableInterview.tableHead.status"),
          width: "150px"
        }
      ]
    };
  },
  computed: {
    currentStateId() {
      return this.$store.state.project.stateId == 3;
    }
  },
  methods: {
    stateName(val) {
      try {
        if (val == 1) {
          return {
            name: this.$t("project.tableInterview.stateName.waiting"),
            class: "",
          };
        } else if (val == 2) {
          return {
            name: this.$t("project.tableInterview.stateName.interviewSent"),
            class: "text-green-500",
          };
        } else if (val == 3) {
          return {
            name: this.$t("project.tableInterview.stateName.dateConfirmed"),
            class: "text-green-500",
          };
        } else if (val == 4) {
          return {
            name: this.$t("project.tableInterview.stateName.dateRejected"),
            class: "text-red-500",
          };
        } else if (val == 5) {
          return {
            name: this.$t("project.tableInterview.stateName.meetCreated"),
            class: "text-green-500",
          };
        } else if (val == 6) {
          return {
            name: this.$t("project.tableInterview.stateName.hired"),
            class: "text-green-500",
          };
        } else if (val == 7) {
          return {
            name: this.$t("project.tableInterview.stateName.inadequate"),
            class: "text-red-500",
          };
        } else if (val == 8) {
          return {
            name: this.$t("project.tableInterview.stateName.dismissed"),
            class: "text-red-500",
          };
        } else if (val == 9) {
          return {
            name: this.$t("project.tableInterview.stateName.referenceList"),
            class: "text-green-500",
          };
        } else {
          return { name: "", class: "" };
        }
      } catch {
        return { name: "", class: "" };
      }
    },
    isInterview(item) {
      if (
        item.interviewPoint >= 0 &&
        item.ikPoint > 0 &&
        item.technicalPoint > 0 &&item.stateId != 6 
      ) {
        return true;
      } else {
        return false;
      }
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(shortList.getAll, {
          params: {
            page: this.currentPage,
            projectId: this.$route.query.id,
            search: this.search,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
          this.isDelete = detail.length > 0 ? detail[0].isDelete : 2;
          this.$emit("List", detail);
          this.$emit("isDelete", this.isDelete);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          this.authController(err);
        });
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.$refs.rejectModalRef.show();
    },
    async handleReject(payload) {
   
      try {
        console.log(payload)
        const response = await this.axios.post(shortList.setInadequate, payload);
        this.successMessage(response.data.message);
        this.getAll();
        this.$emit("refresh", true);
      } catch (err) {
        console.log(err)
        // const errorMessage = err.response ? err.response.data.message : err.message;
        // this.authController(errorMessage);
      }
    },
    async handleContinueWithInadequate(item) {
      this.verifiedMessage(
        this.$t("project.tableInterview.verifiedMessage.continueWithInadequate", { name: item.fullname }),
        this.$t("project.tableInterview.verifiedMessage.continueWithInadequateConfirm"),
        this.$t("project.tableInterview.verifiedMessage.continueWithInadequateDecline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.continueWithInadequate(item);
        }
      });
    },
    async continueWithInadequate(item) {
      this.load = true;
      try {
        const res = await this.axios.put(projectConnect.revertInadequate, {
          projectId: this.$route.query.id,
          cvId: item.cvId,
          isShortlist: true,
        });
        this.successMessage(res.data.message);
        this.$emit("refresh", true);
        this.load = false;
        this.getAll();
      } catch (error) {
        this.authController(error);
        this.load = false;
      } finally {
      }
    },
   
    addReferenceList(item) {
      this.verifiedMessage(
        this.$t("project.tableInterview.verifiedMessage.addReferenceList", { name: item.fullname }),
        this.$t("project.tableInterview.verifiedMessage.addReferenceListConfirm"),
        this.$t("project.tableInterview.verifiedMessage.addReferenceListDecline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          this.List= []
          this.axios.post(referenceAndPayment.add, {
            rowId: item.id,
            projectId: this.$route.query.id,
            fullname: item.fullname,
            cvId: item.cvId,
          })
          .then((res) => {
            this.successMessage(this.$t("project.tableInterview.verifiedMessage.addReferenceListSuccess"));
            this.load = false;
            this.getAll();
          })
          .catch((err) => {
            this.load = false;
           
            this.authController(err);
          });
        }
      });

    },
    goToProfile({item}) {
      console.log(item)
      this.$router.push(`/cv/profile/${item.cvId}`);
    },
    changePoint(val, type) {
      if(type == 2 && !this.canRender(12, 37)){
        return
      }else if(type == 3 && !this.canRender(12, 38)){
        return
      }
      this.$emit("changePoint", {
        detail: val,
        typeId: type,
      });
    },
    showAssessmentModal(item) {
      this.selectedItem = item;
      this.$refs.assessmentHistoryModal.show();
    }
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
  created() {
    // Add permission checks for module 15 action 45 (setInadequate)
    this.handleReject = this.withModuleActionCheck(this.handleReject, 15, 45);
    this.continueWithInadequate = this.withModuleActionCheck(this.continueWithInadequate, 15, 45);
    this.deleteItem = this.withModuleActionCheck(this.deleteItem, 15, 45);
    
    // Add permission check for module 13 action 37 (Reference and Wage)
    this.addReferenceList = this.withModuleActionCheck(this.addReferenceList, 13, 42);
  },
};
</script>
