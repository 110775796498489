<template>
  <div
      class="w-[98%] z-[997] top-[1.5vh] rounded-lg left-[1%] navbar-border-shadow px-12 flex items-center justify-between align-middle sticky h-16   max-lg:px-8 max-md:px-5"
      :class="{ 'bg-white': !isScrolled, 'bg-white/50 backdrop-blur-sm': isScrolled }"
      ref="navbar"
      align="right">

    <div class="flex items-center w-5/12 gap-6 max-md:w-1/12">

      <button @click="toggleSidebar"
              class="rounded-md p-2 cursor-pointer hover:bg-slate-100/80 group transition-all duration-300">
        <Icon icon="eva:menu-outline"
              class="text-[#555B6D] text-2xl transition-all duration-300 group-hover:text-[#4F57AA]"/>
      </button>


      <!-- <div class="bg-slate-50 h-9 w-full flex rounded-md max-md:hidden relative">
        <input type="text" class="h-full bg-slate-50 w-full rounded-md outline-none pl-3 xplaceholder:textcol"
          placeholder="Arama" />
        <div class="flex px-3 justify-center items-center">
          <i class="fa-solid fa-magnifying-glass textcol"></i>
        </div>
      </div> -->

    </div>
    <div class="w-7/12 flex justify-end items-center gap-6 max-md:w-11/12 max-md:gap-4">
      <button @click="refreshPage"
              class="rounded-full p-2 cursor-pointer hover:bg-slate-100/80 hover:text-cvus-primary group transition-all duration-300 relative overflow-hidden">
        <Icon icon="material-symbols:refresh-rounded"
              class="text-slate-300 text-xl transition-all duration-300 group-hover:text-cvus-primary group-hover:rotate-180 group-active:rotate-360"
              :class="{ 'animate-spin': isRefreshing }"/>
        <div
            class="absolute inset-0 bg-slate-50/50 scale-0 group-hover:scale-100 transition-transform duration-300 -z-10 rounded-full"></div>
      </button>
      <cvList/>

      <div class="flex items-center gap-4 z-50 ">
        <button v-if="this.$store.state.userData.authority != 2 && isLog" @click="logToggle" data-v-step="7">
          <icon icon="mdi:history" class="text-slate-300 text-xl "/>
        </button>
        <div v-if="canRender(16, 52)" class="relative">
          <CustomDropdown :width="300">
            <template #toggle>
              <button>
                <icon icon="mdi:briefcase" class="text-slate-300 text-xl"/>
              </button>
            </template>
            <div class="p-2 w-full flex items-center justify-center">
              <companySelect />
            </div>
          </CustomDropdown>
        </div>


        <languageSelect/>
        <notification/>

      </div>
      <!-- <button>
        <img src="@/ass ts/img/mesage.svg" alt="" />
      </button> -->
      <profile/>
    </div>
  </div>
</template>
<script>
import profile from "./components/profile.vue";
import notification from "./components/notification/index.vue";
import languageSelect from "./components/language.vue";
import cvList from "./components/cvList.vue";
import companySelect from "./components/companySelect.vue";
import CustomDropdown from "@/components/customDropdown.vue";
export default {
  name: "nav-bar",
  props: ["toggleSidebar", "isSidebar", "logToggle", "isLog"],
  data() {
    return {
      isScrolled: false,
      scrollTimeout: null,
      appContainer: null,
      isRefreshing: false
    }
  },
  mounted() {
    this.appContainer = document.querySelector('#app-container');
    // Remove any existing listener first
    this.appContainer.removeEventListener('scroll', this.handleScroll);
    // Add passive listener for better performance
    this.appContainer.addEventListener('scroll', this.handleScroll, {passive: true});
    // Initial check
    this.handleScroll();
  },
  methods: {
    refreshPage() {
      this.isRefreshing = true;
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    handleScroll() {
      // Cancel any pending animation frame
      if (this.scrollTimeout) {
        window.cancelAnimationFrame(this.scrollTimeout);
      }

      // Schedule new check using requestAnimationFrame
      this.scrollTimeout = window.requestAnimationFrame(() => {
        this.isScrolled = this.appContainer.scrollTop > 10;
      });


    }
  },
  beforeDestroy() {
    // Clean up any pending animation frame
    if (this.scrollTimeout) {
      window.cancelAnimationFrame(this.scrollTimeout);
    }
    this.appContainer.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    profile,
    notification,
    languageSelect,
    cvList,
    companySelect,
    CustomDropdown
  },


};
</script>

<style>
.navbar-border-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 4px 0 rgba(30, 30, 30, 0.1);
  transition: all 0.3s ease;
}

.navbar-border-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), inset 0 0 4px 0 rgba(30, 30, 30, 0.2);
}
</style>


