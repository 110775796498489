<template>
  <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 max-sm:my-8">
    <customTabs 
      :tabs="tabs" 
      :activeTab="activeTab" 
      @tab-click="handleTabClick"
    />
    
    <div class="py-4">
      <!-- Users tab content -->
      <div v-if="activeTab === 'users'">
        <addUser ref="addUser" @refresh="getUsers()" />
        <editUser ref="editUser" @refresh="getUsers()" :detail="selectedUser" />

        <customTable 
          :items="usersList" 
          :columns="userColumns" 
          :loading="usersLoad"
          :current-page="userCurrentPage"
          :page-count="userPageCount"
          :total-count="userTotalCount"
          :size="'sm'"
          @page-change="handleUserPageChange"
          @row-click="handleUserRowClick"
        >
          <template #header>
            <div class="flex items-center justify-between w-full">
              <div class="flex gap-5 max-sm:flex-col">
                <div>
                  <button
                    v-if="canRender(1, 20)"
                    class="px-8 py-2 h-11 text-sm max-sm:w-[182px] text-white rounded bg-cvus-gradient"
                    @click="$refs.addUser.show()"
                    data-v-step="0"
                  >
                    <i class="fas fa-plus mr-2"></i>
                    {{ $t("user.index.add") }}
                  </button>
                </div>
              </div>
              <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
                <input
                  v-model="userSearch"
                  @keypress.enter="getUserSearch()"
                  type="text"
                  class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                  :placeholder="$t('user.index.table.search')"
                />
                <button class="px-3 textcol absolute right-0">
                  <i @click="getUserSearch()" class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
          </template>
          <template #fullName="{ item }">
            <customTextWrapper 
              :text="item.firstName + ' ' + item.lastName" 
              :maxLength="18" 
              :top="true"
            />
          </template>
          <template #actions="{ item }">
            <div class="flex gap-4 justify-end">
              <button
                v-if="canRender(1, 22)"
                data-v-step="2"
                @click="openEditUser(item)"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-pen-to-square text-base"></i>
                  <tooltip
                    :title="$t('user.index.table.editTooltip')"
                    tipClass="z-50 !top-6 !w-[130px]"
                    :top="true"
                  />
                </div>
              </button>
              <button
                v-if="canRender(1, 21)"
                data-v-step="3"
                @click="deleteUser(item)"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-trash-can text-base"></i>
                  <tooltip
                    :title="$t('user.index.table.deletTooltip')"
                    tipClass="z-50 !top-6 !w-[130px] -right-7"
                    :top="true"
                    rotateClass="ml-[58px]"
                  />
                </div>
              </button>
            </div>
          </template>
        </customTable>
      </div>
      
      <!-- Roles tab content -->
      <div v-if="activeTab === 'roles' && canRender(1, 48)">
        <addRole ref="addRole" @refresh="getRoles()" />
        <editRole ref="editRole" @refresh="getRoles()" :detail="selectedRole" />

        <customTable 
          :items="rolesList" 
          :columns="roleColumns" 
          :loading="rolesLoad"
          :size="'sm'"
          @row-click="handleRoleRowClick"
          :showPagination="false"
        >
          <template #header>
            <div class="flex items-center justify-between w-full">
              <div class="flex gap-5 max-sm:flex-col">
                <div>
                  <button
                    v-if="canRender(1, 49)"
                    class="px-8 py-2 h-11 text-sm max-sm:w-[182px] text-white rounded bg-cvus-gradient"
                    @click="$refs.addRole.show()"
                  >
                    <i class="fas fa-plus mr-2"></i>
                    {{ $t("role.index.add") }}
                  </button>
                </div>
              </div>
             
            </div>
          </template>
          <template #actions="{ item }">
            <div class="flex gap-4 justify-end">
              <button
                v-if="canRender(1, 50)"
                @click="() => ((selectedRole = item), $refs.editRole.show())"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-pen-to-square text-base"></i>
                  <tooltip
                    :title="$t('role.index.table.editTooltip')"
                    tipClass="z-50 !top-6 !w-[130px]"
                    :top="true"
                  />
                </div>
              </button>
              <button
                v-if="canRender(1, 51)"
                @click="deleteRole(item)"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-trash-can text-base"></i>
                  <tooltip
                    :title="$t('role.index.table.deleteTooltip')"
                    tipClass="z-50 !top-6 !w-[130px] -right-7"
                    :top="true"
                    rotateClass="ml-[58px]"
                  />
                </div>
              </button>
            </div>
          </template>
          <template #status="{ item }">
            <badge :variant="item.status ? 'success' : 'error'" >
              {{ item.status ? $t('role.index.table.active') : $t('role.index.table.inactive') }}
            </badge>
          </template>
        </customTable>
      </div>
      
      <!-- Companies tab content -->
      <div v-if="activeTab === 'companies' && canRender(16, 52)">
        <addCompany ref="addCompany" @refresh="getCompanies()" />
        <editCompany ref="editCompany" @refresh="getCompanies()" :detail="selectedCompany" />

        <customTable 
          :items="companiesList" 
          :columns="companyColumns" 
          :loading="companiesLoad"
          :current-page="companyCurrentPage"
          :page-count="companyPageCount"
          :total-count="companyTotalCount"
          :size="'sm'"
          @page-change="handleCompanyPageChange"
          @row-click="handleCompanyRowClick"
        >
          <template #header>
            <div class="flex items-center justify-between w-full">
              <div class="flex gap-5 max-sm:flex-col">
                <div>
                  <button
                    v-if="canRender(16, 53)"
                    class="px-8 py-2 h-11 text-sm max-sm:w-[182px] text-white rounded bg-cvus-gradient"
                    @click="$refs.addCompany.show()"
                  >
                    <i class="fas fa-plus mr-2"></i>
                    {{ $t("company.index.add") }}
                  </button>
                </div>
              </div>
            
            </div>
          </template>
          <template #address="{ item }">
            <customTextWrapper 
              :text="item.address" 
              :maxLength="18" 
              :top="true"
            />
          </template>
          <template #actions="{ item }">
            <div class="flex gap-4 justify-end">
              <button
                v-if="canRender(16, 54)"
                @click="() => ((selectedCompany = item), $refs.editCompany.show())"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-pen-to-square text-base"></i>
                  <tooltip
                    :title="$t('company.index.table.editTooltip')"
                    tipClass="z-50 !top-6 !w-[130px]"
                    :top="true"
                  />
                </div>
              </button>
              <button
                v-if="canRender(16, 55)"
                @click="deleteCompany(item)"
                class="flex items-center h-full"
              >
                <div class="relative group flex justify-center">
                  <i class="fa-solid fa-trash-can text-base"></i>
                  <tooltip
                    :title="$t('company.index.table.deleteTooltip')"
                    tipClass="z-50 !top-6 !w-[130px] -right-7"
                    :top="true"
                    rotateClass="ml-[58px]"
                  />
                </div>
              </button>
            </div>
          </template>
        </customTable>
      </div>
    </div>

    <tour
      :steps="steps"
      :moduleId="1"
      v-if="!$store?.state?.userData?.tourModuleId?.includes(1)"
    />

  </div>
</template>

<script>
import addUser from "./components/addUser.vue";
import editUser from "./components/editUser.vue";
import addRole from "./components/addRole.vue";
import editRole from "./components/editRole.vue";
import addCompany from "./components/addCompany.vue";
import editCompany from "./components/editCompany.vue";
import customTable from "@/components/customTable.vue";
import customTabs from "@/components/customTabs.vue";
import { users } from "@/networking/urlmanager";
import { userstour } from "@/tourmodel/tour.js";
import moment from "moment";
import "moment/locale/tr";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
import customTextWrapper from "@/components/customTextWrapper.vue";
import badge from "@/components/badge.vue";
export default {
  name: "users-page",
  components: {
    addUser,
    editUser,
    addRole,
    editRole,
    addCompany,
    editCompany,
    tour,
    tooltip,
    customTable,
    customTabs,
    customTextWrapper,
    badge,
  },
  data() {
    return {
      steps: userstour,
      
      // Users data
      usersLoad: false,
      userSearch: "",
      userCurrentPage: 0,
      userTotalCount: "",
      userPageCount: 1,
      selectedUser: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        companyId: "",
        companyName: "",
        roleId: "",
        roleName: ""
      },
      usersList: [],
      
      // Roles data
      rolesLoad: false,
      roleSearch: "",
      selectedRole: {
        id: "",
        name: "",
        status: true,
        moduleList: []
      },
      rolesList: [],
      
      // Companies data
      companiesLoad: false,
      companySearch: "",
      selectedCompany: {
        id: "",
        name: "",
        address: "",
        phone: "",
        email: ""
      },
      companiesList: [],
      companyCurrentPage: 0,
      companyTotalCount: "",
      companyPageCount: 1,
    };
  },
  computed: {
    tabs() {
      return [
        { id: 'users', name: this.$t('user.index.tabs.users'), icon: 'mdi:account-group' },
        ...(this.canRender(1, 48) ? [{ id: 'roles', name: this.$t('user.index.tabs.roles'), icon: 'mdi:security' }] : []),
        ...(this.canRender(16, 52) ? [{ id: 'companies', name: this.$t('user.index.tabs.companies'), icon: 'mdi:company' }] : [])
      ];
    },
    activeTab() {
      const tabFromQuery = this.$route.query.tab;
      if (tabFromQuery && this.isValidTab(tabFromQuery)) {
        // Check permissions
        if (tabFromQuery === 'roles' && !this.canRender(1, 48)) {
          return 'users';
        }
        if (tabFromQuery === 'companies' && !this.canRender(16, 52)) {
          return 'users';
        }
        return tabFromQuery;
      }
      return 'users';
    },
    userColumns() {
  return [
    {
      key: 'index',
      label: '#',
      width: '80px',
      format: (_, item, index) => this.userCurrentPage * 15 + (index + 1)
    },
    {
      key: 'fullName',
      label: this.$t("user.index.table.name"),
      width: '200px',
      clickable: true,
      format: (_,item) => {
        return this.formatText(item?.firstName + " " + item?.lastName)
      }
    },
    {
      key: 'roleName',
      label: this.$t("user.index.table.role"),
      width: '120px',
    },
    {
      key: 'companyName',
      label: this.$t("cv.index.tableList.company"),
      width: '180px',
    },
    {
      key: 'email',
      label: this.$t("user.index.table.email"),
      width: '200px'
    }
  ];
},
    roleColumns() {
      // Add dependency on locale to ensure reactivity when language changes
      const locale = this.$i18n.locale;
      
      return [
        {
          key: 'id',
          label: '#',
          width: '80px'
        },
        {
          key: 'name',
          label: this.$t('role.index.table.name'),
          width: '200px',
          clickable: true
        },
        {
          key: 'status',
          label: this.$t('role.index.table.status'),
          width: '100px',
          format: (value) => value ? this.$t('role.index.table.active') : this.$t('role.index.table.inactive')
        }
      ];
    },
    companyColumns() {
      // Add dependency on locale to ensure reactivity when language changes
      const locale = this.$i18n.locale;
      
      return [
        {
          key: 'index',
          label: '#',
          width: '80px',
          format: (_, item, index) => this.companyCurrentPage * 15 + (index + 1)
        },
        {
          key: 'name',
          label: this.$t('company.index.table.name'),
          width: '200px',
          clickable: true
        }
      ];
    }
  },
  methods: {
    // Tab management
    isValidTab(tabId) {
      return this.tabs.some(tab => tab.id === tabId);
    },
    handleTabClick(tabId) {
      this.$router.replace({
        query: { ...this.$route.query, tab: tabId }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    
    // Users methods
    getUserSearch() {
      this.userCurrentPage = 0;
      this.getUsers();
    },
    getUsers() {
      this.usersLoad = true;
      this.usersList = [];
      this.axios
        .get(
          users.getAll + "?page=" + this.userCurrentPage + "&search=" + this.userSearch,
         
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.userTotalCount = totalCount;
          this.userPageCount = pageCount;
          this.usersList = detail;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.usersLoad = false;
        });
    },
    deleteUser(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              users.delete,
              {
                rowId: item.id,
                fullname: item.firstName + " " + item.lastName,
              }
            )
            .then((res) => {
              this.successMessage(res.data.message);
              this.getUsers();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    openEditUser(item) {
      this.selectedUser = item;
      
      this.$nextTick(() => {
        this.$refs.editUser.show();
      });
    },
    handleUserPageChange(page) {
      this.userCurrentPage = page;
      this.getUsers();
    },
    handleUserRowClick({ item }) {
      if (this.canRender(1, 22)) {
      this.selectedUser = item;
      this.$refs.editUser.show();
      }
    },
    
    // Roles methods
    getRoles() {
      if (!this.canRender(1, 48)) {
        this.rolesLoad = false;
        return;
      }
      
      this.rolesLoad = true;
      this.rolesList = [];
      
      this.axios
        .get(users.getRolesAll)
        .then((res) => {
          if (res.data && res.data.data) {
            this.rolesList = res.data.data.map(role => ({
              id: role.roleId,
              name: role.name,
              moduleList: role.moduleList || [],
              status: role.status
            }));
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.rolesLoad = false;
        });
    },
    deleteRole(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(users.deleteRole, {
              data: { roleId: item.id },
             
            })
            .then((res) => {
              this.successMessage(res.data.message || this.$t('role.index.deleteSuccess'));
              this.getRoles();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    handleRoleRowClick({ item }) {
      if (this.canRender(1, 50)) {

      this.selectedRole = {
        id: item.id,
        name: item.name,
        status: item.status,
        moduleList: item.moduleList || []
      };

      this.$refs.editRole.show();
      }
    },
    
    // Companies methods
    getCompanies() {
      if (!this.canRender(16, 52)) {
        this.companiesLoad = false;
        return;
      }
      
      this.companiesLoad = true;
      this.companiesList = [];
      
      this.axios
        .get(
          users.getCompaniesAll + "?page=" + (this.companyCurrentPage || 0) + "&search=" + (this.companySearch || ""),
        
        )
        .then((res) => {
          if (res.data && res.data.data) {
            const { totalCount, detail, pageCount } = res.data.data;
            this.companyTotalCount = totalCount;
            this.companyPageCount = pageCount;
            this.companiesList = detail.map(company => ({
              id: company.id,
              name: company.name
            }));
          }
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.companiesLoad = false;
        });
    },
    deleteCompany(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(users.deleteCompany, {
              data: { rowId: item.id },
             
            })
            .then((res) => {
              this.successMessage(res.data.message || this.$t('company.index.deleteSuccess'));
              this.getCompanies();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    handleCompanyRowClick({ item }) {
      if (this.canRender(16, 54)) {
      this.selectedCompany = item;
      this.$refs.editCompany.show();
      }
    },
    handleCompanyPageChange(page) {
      this.companyCurrentPage = page;
      this.getCompanies();
    }
  },
  created() {
    this.getUsers();
    
    // Only fetch roles and companies if the user has permissions
    if (this.canRender(1, 48)) {
      this.getRoles();
    }
    
    if (this.canRender(16, 52)) {
      this.getCompanies();
    }
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return $t("noDate");
    },
  },
  watch: {
    userCurrentPage() {
      this.getUsers();
    },
    userSearch: function (newSearch) {
      if (newSearch.length === 0) {
        this.getUsers();
      }
    },
    roleSearch: function (newSearch) {
      if (newSearch.length === 0) {
        this.getRoles();
      }
    },
    companySearch: function (newSearch) {
      if (newSearch.length === 0) {
        this.getCompanies();
      }
    }
  },
};
</script>
