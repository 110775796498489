<template>
  <div class="my-10 w-full max-sm:my-8 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4" @mousedown="projectFilter = false">
    <customTable :items="List" :columns="tableColumns" :loading="load" :current-page="currentPage"
      :page-count="pageCount" :total-count="totalCount"
      :search-placeholder="$t('project.index.table.search')"
      @page-change="handlePageChange" 
      height="calc(100vh - 340px)"
      @row-click-all="handleRowClickAll"
      @row-click="handleRowClick" :size="'sm'">
      <!-- Custom cell for project name with tooltip -->
      <template #header>
        <div class="flex items-center justify-between w-full"> 
          <div class="w-fit">
          <button
            v-if="canRender(3, 2)"
            class="px-8 py-2 bg-cvus-gradient transition-all hover:shadow-lg shadow-slate-500/75 hover:opacity-80 h-11 text-sm w-full text-white rounded"
            @click="$refs.addProject.show()" data-v-step="0">
            <i class="fas fa-plus mr-2"></i>
            {{ $t("project.index.add") }}
          </button>
        </div>
          <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
            <input v-model="searchQuery" @keypress.enter="handleSearch(searchQuery)" type="text"
              class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
              :placeholder="$t('project.index.table.search')" />
            <div class="flex h-full items-center !bg-cvus-primary">
              <button class="px-3 textcol absolute right-0">
                <i @click="handleSearch(searchQuery)" class="fa-solid fa-magnifying-glass text-cvus-navy"></i>
              </button>
              <button  @mousedown.stop="showFilter = !showFilter" class="absolute right-9">
                <div class="relative group flex justify-center">
                  <Icon icon="mage:filter" class="text-cvus-primary text-2xl"
                   />
                </div>
              </button>
              <div  @mousedown.stop="showFilter = true" :class="{
                'invisible opacity-0 transition-all ease-in duration-100': !showFilter,
              }"
                class="w-full z-50 rounded mt-1 text-xs absolute right-1 bg-white top-full border border-gray-300 text-black shadow-md">
                <button v-for="(item, index) in projectState" :key="index" @click="handleFilter(item.id)"
                  :class="{ '!bg-gray-100': item.active }"

                  class="w-full py-2 border-b border-gray-100  px-2 text-left">
                  {{ item.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      

      </template>

      <template #id="{ item }">
        <div class="flex items-center gap-2">
          <div class="relative group" v-if="item.stateId == 6">
            <Icon icon="mdi:information-outline" class="text-orange-500 text-2xl" />
            <tooltip :title="$t('project.index.table.tooltip.mustBeEdited')" :top="true" />
          </div>
        <div class="font-bold">
          #{{ item.id }}
        </div>
      </div>

      </template>

      <template #projectName="{ item }">
        <customTextWrapper 
          :text="item.projectName" 
          :maxLength="18" 
          :top="true"
        />
      </template>

      <template #status="{ item }">
        <div class="flex items-center gap-5 w-full max-w-[100px]">
          <Badge v-if="item.stateId == 6 || item.stateId == 5" 
          variant="warning"
          class="w-full text-center justify-center "
          >
            {{ item.stateId == 6 ? $t('projectState.6.name') : $t('projectState.5.name') }}
          </Badge>
          <customSelect 
            v-else-if="canRender(3, 5) && !item.changeStatus && item.stateId != 6 && item.stateId != 5" 
            v-model="item.temporaryStateId" 
            :options="projectStateFiltered"
            @change="(value) => onStatusChange({ target: { value } }, item)"
            :disabled="item.temporaryStateId === 5 || item.stateId == 6"
            :size="'sm'"
            class="w-full"
          />
          <span v-else>
            {{ getStateNameById(item.temporaryStateId) }}
          </span>
        </div>
      </template>


      <!-- Custom cell for applicant count -->
      <template #applicantCount="{ item }">
        <p class="border border-slate-500 py-1 text-xs text-center w-full font-bold text-slate-600 shadow-md rounded">
          {{ item.applicantCount }}
        </p>
      </template>

      <template #cityTitles="{ item }">
        <customTextWrapper 
          :text="item.cityTitles" 
          :maxLength="10" 
          :top="true"
          fallback="-"
        />
      </template>
      <template #actions="{ item }">
        <div class="flex gap-5 justify-center" >
          <button 
            v-if="canRender(3, 3)"
            data-v-step="3" 
            @click="() => { cvId = item.id; $refs.editProject.show() }"
            class="flex items-center h-full">
            <div class="relative group flex justify-center">
              <i class="fa-solid fa-pen-to-square text-base"></i>
              <tooltip :title="$t('project.index.table.tooltip.positionEdit')"
                  :top="true" />
            </div>
          </button>
          <button 
            v-if="canRender(3, 4) && item.stateId != 6"
            @click="() => ((rowId = item.id), (isEdit = item.isEdit), $refs.advert.show())"
            class="flex justify-center items-center text-lg">
            <div class="relative group flex justify-center">
              <i class="fa-solid fa-newspaper"></i>
              <tooltip :title="item.isEdit == 2
                ? $t('project.components.add.advertAddButtonList.advertAdd')
                : $t('project.components.add.advertAddButtonList.view')" 
                :top="true" />
            </div>
          </button>
          <div @click="projectUserRedirection(item)" data-v-step="4" class="flex items-center h-full cursor-pointer" v-if="item.stateId != 6">
            <div class="relative group flex justify-center">
              <i class="fa-solid fa-magnifying-glass text-base"></i>
              <tooltip :title="$t('project.index.table.tooltip.positionDetail')"
                 :top="true" />
            </div>
          </div>
        </div>
      </template>
    </customTable>

    <addProject ref="addProject" @refresh="getAll()" />
    <editlProject ref="editProject" :rowId="cvId" @refresh="getAll()" />
    <advertModal :rowId="rowId" ref="advert" :isEdit="isEdit" @refresh="getAll()" />

    <tour :steps="steps" :moduleId="3" v-if="!$store.state.userData.tourModuleId.includes(3)" />
  </div>
</template>

<script>
import customTable from "@/components/customTable.vue";
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";
import addProject from "./components/add.vue";
import editlProject from "./components/edit.vue";
import advertModal from "./advert/index.vue";
import { project } from "@/networking/urlmanager";
import { projecttour } from "@/tourmodel/tour.js";
import moment from "moment";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
import Badge from "@/components/badge.vue";
import customSelect from "@/components/customSelect.vue";
import customTextWrapper from "@/components/customTextWrapper.vue";

export default {
  name: "project-page",
  components: {
    customTable,
    addProject,
    editlProject,
    tour,
    tooltip,
    advertModal,
    Badge,
    customSelect,
    customTextWrapper,
  },
  data() {
    return {
      temporaryStateId: null,
      load: false,
      search: "",
      searchQuery: "",
      currentPage: this.$route.query.currentPage || 0,
      totalCount: "",
      pageCount: 1,
      projectFilter: false,
      selectedFilter: 0,
    
      steps: projecttour,
      rowId: 0,
      isEdit: 2,
      sort: 0,
      sortField: "",
      showFilter: false,
      cvId: "",
      List: [],
    };
  },
  computed: {
    projectState() {
      return  [
    {
      "id": 0,
      "name": this.$t("projectState.0.name"),
      "active": false
    },
    {
      "id": 1,
      "name": this.$t("projectState.1.name"),
      "active": false
    },
    {
      "id": 2,
      "name": this.$t("projectState.2.name"),
      "active": false
    },
    {
      "id": 3,
      "name": this.$t("projectState.3.name"),
      "active": false
    },
    {
      "id": 4,
      "name": this.$t("projectState.4.name"),
      "active": false
    },
    {
      "id": 5,
      "name": this.$t("projectState.5.name"),
      "active": false
    },
    {
      "id": 6,
      "name": this.$t("projectState.6.name"),
      "active": false
    }
  ]
    },
    projectStateFiltered() {
      return  [
  
    {
      "id": 1,
      "name": this.$t("projectState.1.name"),
      "active": false
    },
    {
      "id": 2,
      "name": this.$t("projectState.2.name"),
      "active": false
    },
    {
      "id": 3,
      "name": this.$t("projectState.3.name"),
      "active": false
    },
    {
      "id": 4,
      "name": this.$t("projectState.4.name"),
      "active": false
    },
  
  ]
    },
    tableColumns() {
      return [
        {
          key: 'id',
          label: this.$t("project.index.table.positionNo"),
          format: (value) => `#${value}`,
          width: '110px',
        },
        {
          key: 'projectName',
          label: this.$t("project.index.table.positionName"),
          clickable: true,
          width: '180px'
        },
        {
          key: 'status',
          label: this.$t("project.index.table.status"),
          width: '150px'
        },
        {
          key: 'addDate',
          label: this.$t("project.index.table.openingDate"),
          format: 'date',
          width: '130px'
        },
        {
          key: 'endDate',
          label: this.$t("project.index.table.closingDate"),
          format: 'date',
          width: '130px'
        },
        {
          key: 'applicantCount',
          label: this.$t("project.index.table.NumberOfCandidates"),
          width: '100px',
          align: 'center',
          clickable: true
        },
        {
          key: 'companyName',
          label: this.$t("project.index.table.companyName"),
          width: '180px'
        },
        {
          key: 'cityTitles',
          label: this.$t("project.index.table.location"),
        },
      ];
    },
  },
  methods: {
    handleSearch(query) {
      this.search = query;
      if (this.currentPage != 0) {
        this.currentPage = 0;
      } else {
        this.getAll();
      }
    },
    handleSort(key) {
      if (key === 'addDate') {
        if (this.sort >= 2) {
          this.sort = 0;
        } else {
          this.sort++;
        }
      } else if (key === 'endDate') {
        if (this.sort < 3 || this.sort >= 5) {
          this.sort = 3;
        } else {
          this.sort = this.sort === 4 ? 0 : 4;
        }
      }
      this.sortField = key;
      this.getAll();
    },

    handlePageChange(page) {
      this.currentPage = page;
    },
    handleRowClick(data) {
      if(data.item.stateId == 6){
        return
      }else{
        this.projectUserRedirection(data.item);
      }
    },
    handleRowClickAll(item){
      if(item.stateId == 6 && this.canRender(3, 3)){
        this.cvId = item.id;
        this.$refs.editProject.show();
      }
    },
    formatText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async projectUserRedirection(item) {
      await this.$store.commit("setStateId", item.stateId);

      this.$router.push({
        path: `/project/user`,
        query: {
          id: item.id,
          projectName: item.projectName,
        },
      });
    },
    getSearch() {
      if (this.currentPage != 0) {
        this.currentPage = 0;
      } else {
        this.getAll();
      }

    },
    addDateOrder() {
      if (this.sort >= 2) {
        this.sort = 0;
      } else {
        this.sort++;
      }
      this.getAll();
    },

    endDateOrder() {
      if (this.sort < 3 || this.sort >= 5) {
        this.sort = 3;
      } else {
        this.sort = this.sort === 4 ? 0 : 4;
      }
      this.getAll();
    },
    getAll() {
      if (this.selectedFilter !== 0) {
        this.getFilter(this.selectedFilter);
        return;
      }

      this.load = true;
      this.List = [];
      this.axios
        .get(project.getAll, {
          params: {
            page: this.currentPage,
            search: this.search,
            sort: this.sort,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.List = detail.map((item) => ({
            ...item,
            temporaryStateId: item.stateId,
          }));
          this.totalCount = totalCount;
          this.pageCount = pageCount;
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    handleFilter(val) {
      this.currentPage = 0;
      this.getFilter(val);
    },
    getFilter(val) {
      this.projectState.forEach((item) => {
        item.active = item.id === val;
      });
      this.projectFilter = false;
      this.selectedFilter = val;
      if (val !== 0) {
        this.load = true;
        this.List = [];
        this.axios
          .get(project.getFilter, {
            params: {
              page: this.currentPage,
              search: this.search,
              stateId: val,
            },
          })
          .then((res) => {
            const { totalCount, detail, pageCount } = res.data.data;
            this.totalCount = totalCount;
            this.pageCount = pageCount;
            this.List = detail.map((item) => ({
              ...item,
              temporaryStateId: item.stateId,
            }));
          })
          .catch((err) => {
            console.error(err);
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
      } else {
        this.getAll();
      }
    },

    deleteItem(item) {
      if (item.stateId != 3) {
        this.verifiedMessage().then((result) => {
          if (result.isConfirmed) {
            this.axios
              .post(project.delete, {
                rowId: item.id,
                projectName: item.projectName,
                stateId: item.stateId,
              })
              .then(() => {
                this.successMessage();
                this.getAll();
              })
              .catch((err) => {
                const errorMessage = err.response
                  ? err.response.data.message
                  : err.message;
                this.authController(errorMessage);
              });
          }
        });
      } else {
        this.warningMesage(this.$t("project.warning.completedPosition"));
      }
    },

    onStatusChange(event, item) {
      // Check permission first
      if (!this.canRender(3, 5)) {
        return;
      }
      
      const newStateId = event.target.value?.id;
      this.verifiedMessage(
        "'" + item.projectName + "' " + this.$t("project.warning.chanceStatus"),
        this.$t("verifiedMessage.yesChance")
      ).then((result) => {
        if (result.isConfirmed) {
          this.applyStatusChange(item, newStateId);
        } else {
          item.temporaryStateId = item.stateId;
        }
      });
    },
    applyStatusChange(item, newStateId) {
      item.changeStatus = true;
      this.axios
        .post(project.changeStatus, {
          rowId: item.id,
          stateId: newStateId,
          projectName: item.projectName,
        })
        .then(() => {
          item.stateId = newStateId;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          item.changeStatus = false;
        });
    },
 

    getDate(val) {
      // internationalization
      if (val) return moment(val).format("LL");
      else return this.$t("project.index.noDate");
    },
    sortIcon(sortType) {
      const icons = {
        1: require("@/assets/project/filteringU.svg"),
        2: require("@/assets/project/filteringD.svg"),
        3: require("@/assets/project/filteringU.svg"),
        4: require("@/assets/project/filteringD.svg"),
      };
      if (
        (sortType === 1 && this.sort < 3) ||
        (sortType === 2 && this.sort >= 3)
      ) {
        return icons[this.sort];
      }
      return null;
    },
   
    getStateNameById(stateId) {
      return this.projectState.find(item => item.id === stateId)?.name;
    },
   
  },
  created() {
    this.getAll();
    
    // Wrap methods with permission checks
    this.deleteItem = this.withModuleActionCheck(this.deleteItem, 3, 3);
    this.applyStatusChange = this.withModuleActionCheck(this.applyStatusChange, 3, 5);
  },

  watch: {
    currentPage() {
      this.$router.push({
        query: {
          currentPage: this.currentPage,
        },
      });
      this.getAll();
    },
    "$i18n.locale"() {
      this.List = this.List.map(item => ({
        ...item,
        changeStatus: true,
      }));
      setTimeout(() => {
        this.List = this.List.map(item => ({
          ...item,
          changeStatus: false,
        }));
      }, 100);
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
    searchQuery: function (newSearch) {
      if (newSearch.length === 0) {
        this.handleSearch('')
      }
    },
  },
};
</script>