<template>
    <div class="group relative max-sm:static text-left">
        <CustomDropdown :width="400" @open="loadCvList" :arrowColor="'#f8f9fa'">
            <!-- Navbar Button -->
            <template #toggle>
                <button
                    class="group flex items-center gap-2 bg-cvus-gradient hover:opacity-80 px-4 py-2 rounded-lg transition-all duration-200">
                    <div class="flex items-center">
                        <Icon icon="hugeicons:file-upload" class="text-[15px] text-slate-100"></Icon>
                    </div>

                    <div class="flex items-center">
                        <Icon icon="mdi:chevron-down" class="text-lg text-white/70 group-hover:text-white/90 transition-colors">
                        </Icon>
                    </div>
                </button>
            </template>

            <!-- Dropdown Content -->
            <div class="w-full max-sm:w-full rounded-lg bg-white z-50">
                <!-- Fixed Header -->
                <div class="sticky top-0 bg-[#f8f9fa] px-4 py-3 border-b rounded-t-lg flex justify-between items-center">
                    <div class="flex gap-3">
                        <h3 class="text-[15px] font-medium text-gray-900">{{ $t("cv.components.cvListModal.resumeList") }}
                        </h3>
                        <div class="bg-[#e7f1ff] text-[#0d6efd] text-xs font-medium px-2 py-1 rounded-md">
                            {{ List.length }}
                        </div>
                    </div>
                    <button @click="closeDropdown">
                        <i class="fas fa-times fa-2x text-[#344CA0] text-base"></i>
                    </button>
                </div>

                <div class="max-h-[300px] overflow-y-auto">
                    <!-- Loading State -->
                    <div v-if="load" class="flex justify-center items-center py-6">
                        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-slate-500"></div>
                    </div>

                    <!-- CV List -->
                    <div v-else-if="List.length > 0" class="divide-y divide-gray-200">
                        <div v-for="item in List" :key="item.id" class="px-4 py-3">
                            <div class="flex justify-between items-start">
                                <div class="flex-1">
                                    <h4 class="text-[14px] font-medium text-gray-900"> <i
                                            class="far fa-file-alt text-gray-400"></i> {{ item.fileName }}</h4>
                                    <p class="text-xs text-gray-500 mt-1">
                                        {{ item.addDate | getDate }}
                                    </p>
                                </div>
                                <div class="flex items-center justify-center min-w-[85px]">
                                    <span
                                        class="bg-[#fff3cd] text-[#856404] text-xs px-2 py-1 rounded-md inline-flex items-center justify-center">
                                        <i class="fas fa-sync-alt animate-spin text-[10px]"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Empty State -->
                    <div v-else class="px-4 py-8 text-center">
                        <i class="fas fa-file-import text-gray-400 text-2xl mb-2"></i>
                        <p class="text-sm text-gray-500">No CVs in parsing queue</p>
                    </div>
                </div>
            </div>
        </CustomDropdown>
    </div>
</template>

<script>
import { cv } from "@/networking/urlmanager";
import moment from "moment";
import CustomDropdown from "@/components/customDropdown.vue";

export default {
    name: "cv-list-loading",
    components: {
        CustomDropdown
    },
    data() {
        return {
            load: false,
            List: [],
            dropdownRef: null
        };
    },
    methods: {
        isPast24Hours(addDate) {
            if (!addDate) return false;
            const addDateTime = new Date(addDate).getTime();
            const currentTime = new Date().getTime();
            const hoursDifference = (currentTime - addDateTime) / (1000 * 60 * 60);
            return hoursDifference > 24;
        },
        async getAll(loading = true) {
            try {
                this.load = loading;
                const res = await this.axios.get(cv.getCvTrain, {
                    params: { stateId: 1 }
                });

                this.List = res.data.data.map((r) => ({
                    id: r.id,
                    originalFile: r.originalFile,
                    stateId: r.stateId,
                    fileName: r.fileName,
                    addDate: r.addDate
                }));

                this.$store.commit("extractCvList", this.List);
                this.load = false;
            } catch (err) {
                console.log(err);
                this.authController(err);
                this.load = false;
            }
        },
        
        loadCvList() {
            this.getAll();
        },
        
        closeDropdown() {
            if (this.dropdownRef) {
                this.dropdownRef.closeDropdown();
            }
        }
    },
    filters: {
        getDate(val) {
            if (val) return moment(val).format("LLL");
            else return this.$t("cvSimilar.index.date");
        },
    },
    mounted() {
        // Get reference to the CustomDropdown component
        this.dropdownRef = this.$children.find(child => child.$options.name === 'CustomDropdown');
    }
};
</script>