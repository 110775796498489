<template>
    <modal :name="`reject-shortlist-modal-${customName}`" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '40%'" :scrollable="true">
      <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50">
        <h1>{{ $t("project.tableInterview.rejectModal.title") }}</h1>
        <button @click="hide()" type="button">
          <i class="fas fa-times"></i>
        </button>
      </div>
  
      <div class="p-4">
        <div class="flex items-center gap-4 mb-4 border-b border-gray-200 pb-4 flex-wrap">
          <label class="flex items-center">
            <input type="radio" v-model="rejectType" value="predefined" class="mr-2">
            {{ $t('project.detail.preInterviewDetail.predefinedNote') }}
          </label>
          <label class="flex items-center">
            <input type="radio" v-model="rejectType" value="custom" class="mr-2">
            {{ $t('project.detail.preInterviewDetail.customNote') }}
          </label>
        </div>
  
        <div v-if="rejectType === 'predefined'" class="mb-[30px] max-w-[400px] overflow-hidden">
          <customSelect
            v-model="selectedRejectId"
            :options="rejectList"
            :title="$t('project.tableInterview.rejectModal.selectReason')"
            :placeholder="$t('project.tableInterview.rejectModal.selectReason')"
            :required="true"
            :error-message="$t('project.tableInterview.rejectModal.selectRequired')"
            ref="rejectSelect"
          >
            <template #no-results>
             <router-link to="/setting/position?tab=inadequate" class="text-blue-500 hover:text-blue-600">
              {{ $t('project.tableInterview.rejectModal.noResultsAddNew') }}
             </router-link>
            </template>
          </customSelect>
        </div>
  
        <customInput 
          v-if="rejectType === 'custom'" 
          v-model="rejectComment" 
          :title="$t('project.tableInterview.rejectModal.note')"
          type="textarea" 
          max="500" 
          :placeholder="$t('project.tableInterview.rejectModal.yourComment')" 
          :rows="4" 
        />
  
        <div class="flex justify-end mt-4">
          <buttonItem 
            :load="rejectLoad" 
            type="button" 
            @click="confirmReject" 
            :text="$t('project.tableInterview.rejectModal.confirmReject')"
            buttonClass="w-full bg-red-500" 
            containerBackground="bg-red-500" 
          />
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import { settingConfigration } from "@/networking/urlmanager";
  import customSelect from "@/components/customSelect.vue";
  import customInput from "@/components/customInput.vue";
  import buttonItem from "@/components/button.vue";
  
  export default {
    name: "RejectModal",
    
    components: {
      customSelect,
      customInput,
      buttonItem,
    },
  
    props: {
      item: {
      },
      customName: {
        type: String,
        default: ''
      }
    },
  
    data() {
      return {
        rejectType: 'predefined',
        selectedRejectId: null,
        rejectComment: '',
        rejectList: [],
        rejectLoad: false,
      };
    },
  
    created() {
      this.fetchRejectList();
    },
  
    methods: {
      async fetchRejectList() {
        try {
          const response = await this.axios.get(settingConfigration.getInadequateList);
          this.rejectList = (response.data.data || []).map(item => ({
            id: item.id,
            name: item.inadequateMessage
          }));
        } catch (error) {
          console.error("Error fetching reject messages:", error);
        }
      },
  
      show() {
        this.rejectType = 'predefined';
        this.selectedRejectId = null;
        this.rejectComment = '';
        this.$modal.show(`reject-shortlist-modal-${this.customName}`);
      },
  
      hide() {
        this.$modal.hide(`reject-shortlist-modal-${this.customName}`);
        this.$emit('close');
      },
  
      async confirmReject() {
        if (this.rejectType === 'custom' && this.rejectComment?.length < 5) {
          this.warningMesage(this.$t("project.tableInterview.rejectModal.charactersLong"));
          return;
        }
  
        if (this.rejectType === 'predefined' && !this.selectedRejectId) {
          this.warningMesage(this.$t("project.tableInterview.rejectModal.selectReasonMessage"));
          return;
        }
  
        this.rejectLoad = true;
        const email = this.parseEmail(this.item.contactEmail)
        const payload = {
          projectId: this.$route.query.id,
          cvId: this.item.cvId,
          inadequateNote: this.rejectType === 'custom' ? this.rejectComment : undefined,
          inadequateId: this.rejectType === 'predefined' ? this.selectedRejectId : undefined,
          fullname: this.item.fullname,
          email: email,
          projectName: this.$route.query.projectName,
          isShortlist: true
        };
        this.$emit('confirm', payload);
        this.rejectLoad = false;
        this.hide();
      },
    },
  };
  </script>