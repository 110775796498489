  <template>
     <div v-if="load || !isContent" class="w-full text-left mt-10">
      <p
        v-if="!load && !isContent"
        class="w-full mb-5 font-extralight"
      >
      {{ $t("cv.profile.information.components.dataLoader.cvNotFound") }} <b>{{ title }}</b>  {{ $t("cv.profile.information.components.dataLoader.notFound") }}
      </p>
      <p v-else-if="load"
        class="w-full mb-5 font-extralight flex items-center gap-2"
      >
        <i class="fas fa-spinner fa-spin"></i>  {{ $t("cv.profile.information.components.dataLoader.loadText") }}
      </p>
    </div>
</template>
<script>
export default {
    props:['load',"isContent",'title'],
    name:'dataLoader'
}
</script>