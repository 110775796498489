<template>
  <div class="w-full z-10">
    <customSelect
      v-model="selectedSector"
      :options="filterList"
      :loading="loading"
      :searchable="true"
      :multiple="false"
      :return-object="true"
      @search="asyncFind"
      @change="handleChange"
    >
    </customSelect>
  </div>
</template>

<script>
import customSelect from "@/components/customSelect.vue";
import { utility } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value"],
  components: {
    customSelect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedSector: null,
      List: [],
      filterList: [],
      fuse: null,
      loading: false,
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
    async getAll() {
      try {
        this.loading = true;
        const response = await this.axios.get(utility.getSectorAll);
        this.List = response.data.data.map(sector => ({
          id: sector.id,
          name: sector.name
        }));
        this.filterList = [...this.List];

        this.fuse = new Fuse(this.List, {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        });
        
        // Set the selected sector if value exists
        this.setSelectedSector();
      } catch (error) {
        console.error("Sektör bilgileri yüklenirken bir hata oluştu:", error);
      } finally {
        this.loading = false;
      }
    },
    setSelectedSector() {
      if (this.value && Array.isArray(this.value) && this.value.length > 0 && this.value[0].sectorId) {
        this.selectedSector = this.List.find((l) => l.id == this.value[0].sectorId) || null;
      } else if (this.value && this.value.id) {
        this.selectedSector = this.List.find((l) => l.id == this.value.id) || null;
      }
    }
  },
  async created() {
    await this.getAll();
  },
  watch: {
    value() {
      this.setSelectedSector();
    },
    List() {
      this.setSelectedSector();
    }
  },
};
</script>
