import { render, staticRenderFns } from "./editRole.vue?vue&type=template&id=30ec3d99"
import script from "./editRole.vue?vue&type=script&lang=js"
export * from "./editRole.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports